import { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useNavigate,useForceUpdate } from "react-router-dom";

function PrivateRoutes({ component: Component, ...restOfProps }) {
  const loginpage = useNavigate();

  //   const isAuthenticated = localStorage.getItem("isAuthenticated");
  //   let auth1 = { token: isAuthenticated };
  //   let isExpired = true;
  //   //console.log("innnnnnn");
  //   try {
  //     const istoken = localStorage.getItem("token");
  //     let jwt1 = jwt(istoken).exp;
  //     var notExpired = true;
  //     var dateNow = new Date();
  //     if (Date.now() >= jwt1 * 1000) {
  //       notExpired = false;
  //     }
  //     // if (jwt1 * 1000.05 < dateNow.getTime()) {
  //     //   notExpired = false;
  //     // }

  //     return auth1.token && notExpired ? (
  //       <Outlet />
  //     ) : (
  //       (window.location.href = "/login")
  //     );
  //   } catch (error) {
  //     isExpired = true;
  //     localStorage.setItem("login", false);
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("isAuthenticated");
  //     return isExpired && <Navigate to="/login" />;
  //   }\
  
useEffect(()=>{
  var logintest = localStorage.getItem("login");
  //console.log("$$$$$$$$$$$$$")
  if (logintest == "false") {
  } else {
    loginpage("/login");
  }
},[])
}
export default PrivateRoutes;

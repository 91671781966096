import React, { useEffect, useState } from "react";
import "../style/maincompstyle/sidebar.scss";
import { NavLink } from "react-router-dom";
// import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
// import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
// import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
// import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { useSelector, useDispatch } from "react-redux";
import { sidenav } from "../redux/reducer/counterslice";
import { sidebar,dvtabs } from "../redux/reducer/counterslice";
import $ from "jquery";
// import SickIcon from '@mui/icons-material/Sick';
// import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
// import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
// import VaccinesIcon from '@mui/icons-material/Vaccines';


function SideBar() {
  const sideactive = useSelector((state) => state.counter.sidebarnav);
  const sidebarmenustate = useDispatch();
  const sidebarstate = useSelector((state) => state.counter.sidebar);
  const [expand, setexpand] = useState(false);
  const sidebaractive = useDispatch();
  const tabsstate=useDispatch();
//console.log(sidebarstate)

  //console.log(expand)
$(window).resize(function() {
  // //console.log(  
  //   window.innerWidth
  //   )
    if(window.innerWidth<=920){
      sidebaractive(sidenav(false));
    }
});

const navData = [
    // {
    //   id: 0,
    //   icon: <HomeRoundedIcon style={{ padding: "10px", fontSize: "46px" }} />,
    //   text: "DashBoard",
    //   class:
    //     "adminusernav" +
    //     `${sidebarstate == "DashBoard" ? " activesidemenu" : ""}`,
    //   link: "/",
    // },
    // {
    //   id: 1,
    //   icon: (
    //     <AccountCircleRoundedIcon
    //       style={{ padding: "10px", fontSize: "46px" }}
    //     />
    //   ),
    //   text: "Admin User",
    //   class:
    //     "adminusernav" +
    //     `${sidebarstate == "Admin User" ? " activesidemenu" : ""}`,
    //   link: "/AdminUser",
    // },
    {
      id: 2,
      icon: (
        // <PersonAddAltRoundedIcon
        //   style={{ padding: "10px", fontSize: "46px" }}
        // />
        <i class="bi bi-house-add"  style={{ padding: "9px", fontSize: "26px" }}></i>
        ),
      text: "Property",
      class:
        "doctorsidebar" +
        `${sidebarstate == "Property" ? " activesidemenu" : ""}`,
      link: "/",
    },
    // {
    //   id: 3,
    //   icon: <PersonRoundedIcon style={{ padding: "10px", fontSize: "46px" }} />,
    //   text: "Patient",
    //   class:
    //     "doctorsidebar" +
    //     `${sidebarstate == "Patient" ? " activesidemenu" : ""}`,
    //   link: "/Patient",
    // },
    // {
    //   id: 4,
    //   icon: <LocalHospitalIcon style={{ padding: "10px", fontSize: "46px" }} />,
    //   text: "Settings",
    //   // class:
    //   //   `${sidebarstate == "specialty" ? " activesidemenu" : ""}`,
    //   // link: "/Patient",
    //   subMenus: [
    //     {
    //       name: "spa",
    //       link: "/specialty",
    //       icon: (
    //         // <HealthAndSafetyIcon style={{ padding: "10px", fontSize: "46px" }} />
    //         <i class="fa fa-stethoscope" style={{ padding: "13px", fontSize: "26px" }}></i>
    //       ),
    //       text: "Specialty",
    //       class:
    //         "doctorsidebar" +
    //         `${sidebarstate == "Specialty" ? " activesidemenu" : ""}`,
    //     },
    //     {
    //       name: "sym",
    //       link: "/Symptoms",
    //       icon: (
    //         <SickIcon style={{ padding: "10px", fontSize: "46px" }} />
            
    //       ),
    //       text: "Symptoms",
    //       class:
    //         "doctorsidebar" +
    //         `${sidebarstate == "Symptoms" ? " activesidemenu" : ""}`,
    //     },
    //     {
    //       name: "pharmacy",
    //       link: "/Pharmacy",
    //       icon: (
    //         <VaccinesIcon style={{ padding: "10px", fontSize: "46px" }} />
            
            
    //       ),
    //       text: "Pharmacy",
    //       class:
    //         "doctorsidebar" +
    //         `${sidebarstate == "Pharmacy" ? " activesidemenu" : ""}`,
    //     },
    //     {
    //       name: "img",
    //       link: "/Banner",
    //       icon: (
    //         <VaccinesIcon style={{ padding: "10px", fontSize: "46px" }} />
            
            
    //       ),
    //       text: "ImageUpload",
    //       class:
    //         "doctorsidebar" +
    //         `${sidebarstate == "ImageUpload" ? " activesidemenu" : ""}`,
    //     },

    //   ],
    // },
  ];
  const sidebarhandleclick = (e) => {
    if(e!="Settings"){
      sidebarmenustate(sidebar(e));

    }
    
              tabsstate(dvtabs("About"))
    
    // if (e == "Admin User") {
    //   sidebarmenustate(sidebar("Admin User"));
    // }
    // if (e == "Doctor") {
    //   sidebarmenustate(sidebar("Doctor"));
    // }
    // if (e == "DashBoard") {
    //   sidebarmenustate(sidebar("DashBoard"));
    // }
    // if (e == "Patient") {
    //   sidebarmenustate(sidebar("Patient"));
    // }
    //     if (e == "Specialty") {
    //   sidebarmenustate(sidebar("Specialty"));
    // }
    
    //       // sidebarmenustate(sidebar(e));
    //       if (e == "Symptoms") {
    //         sidebarmenustate(sidebar("Symptoms"));
    //       }
      
  };

  const hoverstate = (e) => {
    if (!sideactive) {
      //console.log(e);
      $(".sidebarclose").css("width", "240px");
      $(".linkTextclosed").css("display", "block");
      $(".arrow").css("display", "block")

    }
  };
  const hoverleave = (e) => {
    if (!sideactive) {
      //console.log(e);
      $(".sidebarclose").css("width", "75px");
      $(".linkTextclosed").css("display", "none");
      setexpand(false)
      $(".arrow").css("display", "none")
    }
  };
useEffect(()=>{
if(!sideactive){
  setexpand(false)
}
},[sideactive])
  const expandfunc=()=>{
    setexpand(!expand)
  }
  return (
    <>
      <div
        className={sideactive ? "sidebar" : "sidebarclose"}
        id="sidebar"
        onMouseEnter={(e) => hoverstate(e)}
        onMouseLeave={(e) => hoverleave(e)}
      >
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul className="sidebar_content">
              {navData.map((item) => {
                // //console.log(item);
                return (
                  <>
                    <NavLink to={item.link} style={{ textDecoration: "none" }}>
                      <li
                        key={item.id}
                        className={`sideitem ${item.class}`}
                        href={item.link}
                        onClick={(e) => {sidebarhandleclick(item.text)
                        if(item.subMenus){
                          expandfunc(e)

                        }
                        }}
                      >
                        <div href={item.link}>{item.icon}</div>
                        <div
                          className={sideactive ? "linkText" : "linkTextclosed"}
                          style={{ display: sideactive ? "block" : "" }}
                        >
                          <NavLink className="nav-link" to={item.link}>
                            {item.text}
                          </NavLink>
                        </div>
                        {item.subMenus?(<>
                          <i class={"bi bi-caret-right-fill arrow"+`${expand ? " arrowexpand" : ""}`}  style={{ display: sideactive ? "block" : "none" }} onClick={(e)=>{
                            expandfunc(e)
                          }}></i>
                          </>):""}


                      </li>
                    </NavLink>
                    <ul className={`sub-menu ${expand ? " active" : ""} `}>
                      {item.subMenus?.map((ele) => {
                        return (
                          <NavLink
                            to={ele.link}
                            style={{ textDecoration: "none" }}
                          >
                            <li
                              key={ele.id}
                              className={`sideitem ${ele.class}`}
                              href={ele.link}
                              onClick={(e) => sidebarhandleclick(ele.text)}
                            >
                              <div href={ele.link}>{ele.icon}</div>
                              <span
                                className={
                                  sideactive ? "linkText" : "linkTextclosed"
                                }
                                style={{ display: sideactive ? "block" : "" }}
                              >
                                <NavLink className="nav-link" to={ele.link} style={{ textDecoration: "none" }}>
                                  {ele.text}
                                </NavLink>
                              </span>
                            </li>
                          </NavLink>                          
                        );
                      })}
                    </ul>
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;

import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../style/DeanProperty/Property.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { editProperty } from "../redux/reducer/counterslice";
import { editpropertyuser } from "../redux/reducer/counterslice";
import { editpropertyuserid } from "../redux/reducer/counterslice";
import { baseurl, deleteapi } from "../../text/apidata";

import { propertyget } from "../../text/apidata";
import BasicTable from "../maincomponent/reacttable/table";
import { FormControl } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { set } from "date-fns";
import TextField from "@mui/material/TextField";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Swal from "sweetalert2";

var id1;
const Property = (props) => {
  const sideactive = useSelector((state) => state.counter.sidebarnav);
  const [tableheaderdata, settableheaderdata] = useState([]);
  const [dtpageindex, setdtPageindex] = useState(1);
  const [dtpagesize, setdtPagesize] = useState(10);
  const [datacount, setdatacount] = useState();

  console.log(datacount, dtpageindex, "dcdc");
  const [searchQuery, setSearchQuery] = useState("");
  const [search, setsearch] = useState(false);
  const [all1, setall1] = useState(true);
  const [all2, setall2] = useState(true);
  const [OrderDispaly, setOrderDisplay] = useState({});
  const [isactive, setisactive] = useState("");
  const [drop1, setdrop1] = useState("all");
  const [drop2, setdrop2] = useState("");
  const [showButton, setShowButton] = useState(false);

  const [sold, setsold] = useState("");
  const [Refresh, setRefresh] = useState(false);

  const propertycreate = useNavigate();
  const editProperty = useNavigate();
  const property = useNavigate();
  const dispatch = useDispatch();
  const edituserpropertyidvalue = useDispatch();
  const [popup, setpopup] = useState(false);
  const [id, setid] = useState();

  const deletebanner = (e) => {
    console.log(e);
    setid(e);
    setpopup(true);
  };

  const confirm = () => {
    props.loaderchange("true");
    axios({
      method: "delete",
      url: deleteapi + id + "/",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token") + "",
      },
    })
      .then((res) => {
        console.log(res.data);
        // window.location.href = "/Banner";
        Swal.fire("Deleted!", "data has been deleted.", "success").then(
          (result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          }
        );
        setpopup(false);

        props.loaderchange("false");
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == "401") {
          Swal.fire({
            title: "Token Expired Login Again!",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              property("/login");
              localStorage.clear();
              // window.location.reload();
            }
          });
        }
      });
  };
  const dropdownhandlechange = (e, event) => {
    if (e) {
      // //console.log(e, "lsdlkljasdljadjladasld");
      dispatch(editpropertyuserid(e));
      editProperty("/propertyedit");
      window.location.reload();
      //   props.enableditview("activedoctoredit");
      // dispatch(editpropertyuser("activedoctoredit"));
      // edituserpropertyidvalue(editpropertyuserid(e));
    }
  };

  // useEffect(() => {
  //   props.loaderchange("true");

  //   // console.log(drop1, drop2, "qqq");
  //   axios({
  //     method: "get",
  //     url: propertyget,
  //     headers: {
  //       Authorization: "Bearer " + localStorage.getItem("token") + "",
  //     },
  //     params: {
  //       page: searchQuery ? "1" : dtpageindex,
  //       page_size: dtpagesize,
  //       search: searchQuery,
  //       is_active: localStorage.getItem("drop2"),
  //       status: localStorage.getItem("drop1"),
  //     },
  //   })
  //     .then((res) => {
  //       // console.log("yesyesyesyesyes", res.data);
  //       settableheaderdata(res.data.data);

  //       // setdatacount(
  //       //   res.data.data_count.available + res.data.data_count.is_sold
  //       // );

  //       if (drop1 === "0") {
  //         setdatacount(res.data.data_count.completed);
  //         console.log(res.data.data_count.completed, "com");
  //       } else if (drop1 === "1") {
  //         setdatacount(res.data.data_count.dev_project);
  //         console.log(res.data.data_count.dev_project, "pfd");
  //       } else if (drop1 === "2") {
  //         setdatacount(res.data.data_count.fully_refurbed);
  //         console.log(res.data.data_count.fully_refurbed, "frp");
  //       } else if (drop1 === "3") {
  //         setdatacount(res.data.data_count.require_refurbishment);
  //         console.log(res.data.data_count.require_refurbishment, "prr");
  //       } else if (drop1 === "all") {
  //         setdatacount(
  //           res.data.data_count.completed + res.data.data_count.dev_project + res.data.data_count.fully_refurbed + res.data.data_count.require_refurbishment
  //         );
  //         console.log(
  //           res.data.data_count.available + res.data.data_count.is_sold,
  //           "allll"
  //         );
  //       }

  //       let l1 = {};
  //       res.data.data.length > 0 &&
  //         res.data.data.map((ele) => {
  //           l1[ele.id] = ele.order_display;
  //         });
  //       setOrderDisplay(l1);
  //       props.loaderchange("false");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       if (error.response.status == "401") {
  //         Swal.fire({
  //           title: "Token Expired Login Again!",
  //           showClass: {
  //             popup: "animate__animated animate__fadeInDown",
  //           },
  //           hideClass: {
  //             popup: "animate__animated animate__fadeOutUp",
  //           },
  //         }).then((result) => {
  //           if (result.isConfirmed) {
  //             property("/login");
  //             localStorage.clear();
  //             // window.location.reload();
  //           }
  //         });
  //       }
  //       // //console.log(error, "noonononnononono");
  //       props.loaderchange("false");

  //       // setOrderDisplay((prev)=>)
  //       // setdtPageindex(dtpageindex - 1);
  //       // props.popupalert("true");
  //       // props.popuptext("No Data Available");
  //       setTimeout(() => {
  //         // props.popupalert("false");
  //       }, 2000);
  //     });
  // }, [dtpageindex, dtpagesize, searchQuery, drop1, drop2, Refresh]);

  useEffect(() => {
    props.loaderchange("true");

    axios({
      method: "get",
      url: propertyget,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token") + "",
      },
      params: {
        page: searchQuery ? "1" : dtpageindex,
        page_size: dtpagesize,
        search: searchQuery,
        is_active: localStorage.getItem("drop2"),
        status: localStorage.getItem("drop1"),
      },
    })
      .then((res) => {
        settableheaderdata(res.data.data);

        if (drop1 === "0") {
          setdatacount(res.data.data_count.completed);
          console.log(res.data.data_count.completed, "com");
        } else if (drop1 === "1") {
          setdatacount(res.data.data_count.dev_project);
          console.log(res.data.data_count.dev_project, "pfd");
        } else if (drop1 === "2") {
          setdatacount(res.data.data_count.fully_refurbed);
          console.log(res.data.data_count.fully_refurbed, "frp");
        } else if (drop1 === "3") {
          setdatacount(res.data.data_count.require_refurbishment);
          console.log(res.data.data_count.require_refurbishment, "prr");
        } else {
          setdatacount(
            res.data.data_count.completed +
              res.data.data_count.dev_project +
              res.data.data_count.fully_refurbed +
              res.data.data_count.require_refurbishment
          );
          console.log(
            res.data.data_count.available + res.data.data_count.is_sold,
            "allll"
          );
        }

        let l1 = {};
        res.data.data.length > 0 &&
          res.data.data.map((ele) => {
            l1[ele.id] = ele.order_display;
          });
        setOrderDisplay(l1);
        props.loaderchange("false");
      })
      .catch((error) => {
        // Error handling code
        props.loaderchange("false");
        console.log(error);
        if (error.response && error.response.status === 401) {
          Swal.fire({
            title: "Token Expired Login Again!",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              property("/login");
              localStorage.clear();
              // window.location.reload();
            }
          });
        }
      });
  }, [dtpageindex, dtpagesize, searchQuery, drop1, drop2, Refresh]);

  const numberchange = (row, e) => {
    // //console.log(row, e);
  };

  const ordernumber = useCallback(
    (ele) => {
      // if (id1 != "" && id1 != null && id1 != undefined) {
      //   const div = document.getElementById(id1);
      //   div.focus();
      //   console.log(div, "div");
      // }
    },
    [OrderDispaly]
  );

  useEffect(() => {
    if (id1 != "" && id1 != null && id1 != undefined) {
      const div = document.getElementById(id1);
      if (div) {
        div.focus();
      }
    }
  }, [id1]);

  const TableColumn = useMemo(
    () => [
      {
        Header: "Address",
        accessor: "property_address",
        Cell: (row) => {
          // //console.log(row.row.original.agent_phone_number)
          const shorten =
            row.row.original.property_address.toString().length > 20
              ? `${row.row.original.property_address
                  .toString()
                  .substring(0, 50)}...`
              : `${row.row.original.property_address.toString()}`;
          return (
            <>
              <span>
                <div
                  style={{ marginTop: "3px" }}
                  data-tooltip-id="admin"
                  data-tooltip-content={row.row.original.property_address.toString()}
                >
                  {shorten}
                </div>
              </span>
            </>
          );
        },
      },

      {
        Header: "Tenure",
        accessor: "tenure",
        Cell: (row) => (
          // //console.log(row.row.original,"abcccc")
          // return (
          <>
            <span>
              <div
                style={{ marginTop: "3px" }}
                data-tooltip-id="admin"
                data-tooltip-content={String(row.row.original.tenure)}
              >
                {String(row.row.original.tenure)}
              </div>
            </span>
          </>
        ),
        // },
      },
      {
        Header: "Price",
        accessor: "price",
        Cell: (row) => {
          // //console.log(row.row.original.price)
          const shorten1 =
            row.row.original.price.toString().length > 20
              ? `${row.row.original.price.toString().substring(0, 50)}...`
              : `${row.row.original.price.toString()}`;

          return (
            <>
              <span>
                <div
                  style={{ marginTop: "3px" }}
                  data-tooltip-id="admin"
                  data-tooltip-content={row.row.original.price.toString()}
                >
                  {shorten1}
                </div>
              </span>
            </>
          );
        },
      },
      {
        Header: "Created Date",
        accessor: "created_date",
        Cell: (row) => (
          // //console.log(row.row.original.created_date)
          // return (
          <>
            <span>
              <div
                style={{ marginTop: "3px" }}
                data-tooltip-id="admin"
                data-tooltip-content={String(row.row.original.created_date)}
              >
                {String(row.row.original.created_date.split("T")[0])}
              </div>
            </span>
          </>
        ),
        // },
      },
      {
        Header: "Order Display",
        accessor: "",
        Cell: (row) => (
          // return (
          <input
            type="number"
            // id="input"
            // name={row.row.original.id}
            ref={ordernumber}
            value={row.row.original.order_display}
            id={row.row.original.id}
            onChange={(e) => {
              row.row.original.order_display = e.target.value;
              id1 = row.row.original.id;
              setOrderDisplay((prev) => {
                return { ...prev, [row.row.original.id]: e.target.value };
              });
              setShowButton(true);
            }}
          ></input>
        ),
        // },
      },
      {
        Header: "Active",
        accessor: "",
        Cell: (row) => (
          // //console.log(row.row.original.is_active, "isactiveisactive")
          // return (
          <>
            <span>
              <div
                style={{ marginTop: "3px" }}
                data-tooltip-id="admin"
                data-tooltip-content={
                  row.row.original.is_active == true ? "Yes" : "No"
                }
              >
                {row.row.original.is_active == true ? "Yes" : "No"}
              </div>
            </span>
          </>
        ),
        // },
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (row) => (
          // return (
          <>
            <div class="actions">
              <button
                type="button"
                class="btn btn-danger btn-sm edit m-1"
                onClick={(e) => {
                  window.history.pushState({ page: 1 }, null, "./propertyedit");
                  dropdownhandlechange(row.row.original.id, e);
                }}
              >
                <i class="bi bi-pencil-fill"></i> Edit
              </button>
              <button
                type="button"
                class="btn btn-danger btn-sm delete m-1"
                onClick={(e) => deletebanner(row.row.original.id, e)}
              >
                <i class="bi bi-trash3"></i> Delete
              </button>
            </div>
          </>
        ),
        // },
      },
    ],
    [OrderDispaly]
  );
  // const columns = useMemo(() => TableColumn);

  const creatfunction = (e) => {
    propertycreate("/PropertyCreate");
    dispatch(editpropertyuser("create"));
    //window.location.href = "/NewAdminUser";
  };

  const pagesize = (arg) => {
    setdtPagesize(arg);
  };

  const pageindex = (arg) => {
    setdtPageindex(arg);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const test = (event) => {
    setsearch(!search);
  };
  const [value, setvalue] = useState("");

  const change1 = (e) => {
    setdrop1(e.target.value);
    // console.log(e.target.value, "eee");
    setdtPageindex(1);
    setdtPagesize(10);

    localStorage.setItem("drop1", e.target.value);
    setvalue(e.target.value);

    // Adjust the page size based on the selected dropdown value if needed
    // if (e.target.value === "1") {
    //   setdtPagesize(10); // Set page size for option 1
    // } else {
    //   // Set other page sizes if required for other options
    // }

    //   if(e.target.value=="Sold"){
    //   setall1(false)
    //   setsold(true)
    // }
    // else if(e.target.value=="Available"){
    //   setall1(false)

    //   setsold(false)
    // }
    //console.log(e.target.value, sold, all1);
  };

  const handlePut = (e) => {
    axios({
      method: "put",
      url: baseurl + "/editwhole/",
      data: { multipe_data: tableheaderdata },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token") + "",
      },
    })
      .then((response) => {
        setShowButton(false);
        // setRefresh(!Refresh);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == "401") {
          Swal.fire({
            title: "Token Expired Login Again!",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              property("/login");
              localStorage.clear();
              // window.location.reload();
            }
          });
        }
      });
  };

  const change2 = (e) => {
    setdrop2(e.target.value);
    localStorage.setItem("drop2", e.target.value);
  };

  return (
    <>
      <div
        // className={sideactive ? "proppage-sa" : "proppage"}
        className="proppage"
      >
        <div className="page-header">
          <div className="row">
            <div class="col-sm-5 col-auto">
              <h3 class="page-title" style={{ fontWeight: "800" }}>
                List of Properties
              </h3>
              <ul class="breadcrumb"></ul>
            </div>
            <div className="col-sm-6 col" style={{ display: "flex" }}>
              {/* <button
              className={all ? "active" : "all"}
              onClick={(e) => {
                setall(!all);
                setsold(false);
                setisactive(false);
              }}
            >
              All
            </button>
            <button
              className={isactive ? "active" : "isactive"}
              onClick={(e) => {
                if (all == false) {
                  setisactive(!isactive);
                } else {
                  setall(false);
                  setisactive(!isactive);
                }
              }}
            >
              Is Active
            </button>
            <button
              className={sold ? "active" : "sold"}
              onClick={(e) => {
                if (all == false) {
                  setsold(!sold);
                } else {
                  setall(false);
                  setsold(!sold);
                }
              }}
            >
              Sold
            </button> */}
              <select
                className="form-control1 m-2"
                onChange={(e) => {
                  change1(e);
                }}
                value={localStorage.getItem("drop1")}
              >
                <option value={"all"} selected>
                  All
                </option>
                <option value={"0"}>Completed</option>
                <option value={"1"}>Properties For Development</option>
                <option value={"2"}>Fully Refurbed Properties</option>
                <option value={"3"}>Properties Requiring Refurbishment</option>

                {/* <option value={false}>Available</option> */}
              </select>
              <select
                className="form-control1 m-2"
                onChange={(e) => {
                  change2(e);
                }}
                value={localStorage.getItem("drop2")}
              >
                <option value="All" selected>
                  All
                </option>
                <option value={true}>Active</option>
                <option value={false}>In Active</option>
              </select>
            </div>

            <div class="col-sm-1 col">
              <a
                onClick={(e) => {
                  window.history.pushState(
                    { page: 1 },
                    null,
                    "./PropertyCreate"
                  );
                  creatfunction(e);
                }}
                style={{
                  backgroundColor: "#00d0f1",
                  border: "1px solid #00d0f1",
                  color: "white",
                  float: "right",
                  marginRight: "25px",
                }}
                data-toggle="modal"
                class="btn btn-primary float-right mt-2"
              >
                Add
              </a>
            </div>

            {/* table */}
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body" style={{ padding: "1.5rem" }}>
                    {showButton && (
                      <>
                        <button
                          onClick={(e) => handlePut()}
                          className="save btn btn-danger btn-sm edit m-1"
                          style={{ float: "right" }}
                        >
                          Save
                        </button>
                        <button
                          onClick={(e) => {
                            setShowButton(false);
                            setRefresh(!Refresh);
                          }}
                          className="undo btn btn-danger btn-sm edit m-1"
                          style={{ float: "right" }}
                        >
                          Undo
                        </button>
                      </>
                    )}

                    <form onSubmit={handleSubmit}>
                      <div
                        class="input-group rounded"
                        style={{ width: "250px", height: "40px" }}
                      >
                        <input
                          type="search"
                          class="form-control rounded"
                          aria-label="Search"
                          aria-describedby="search-addon"
                          onInput={(e) => {
                            setSearchQuery(e.target.value);
                          }}
                          label="Search"
                          placeholder="Search..."
                        />
                        <span
                          class="input-group-text border-0 bg-transparent"
                          id="search-addon"
                        >
                          <i
                            class="bi bi-search"
                            style={{
                              color: "rgb(0, 208, 241)",
                              width: "20px",
                              height: "20px",
                            }}
                          ></i>
                        </span>
                      </div>
                    </form>

                    <div className="table-responsive">
                      <BasicTable
                        columns={TableColumn}
                        data={tableheaderdata}
                        pagesize={pagesize}
                        pageindex={pageindex}
                        dtpagesize={dtpagesize}
                        dtpageindex={dtpageindex}
                        datacount={datacount}
                        value={value}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip
          id="admin"
          arrowColor="transparent"
          place="bottom-start"
          delayShow={200}
          style={{
            backgroundColor: "#707070",
            fontSize: "10px",
            textAlign: "center",
            padding: "5px",
            marginRight: "10px",
            fontWeight: "400",
            width: "auto",
          }}
        />
      </div>
      {popup && (
        <>
          <div className="logout_popup">
            <div class="container1">
              <div class="cookiesContent" id="cookiesPopup">
                <button
                  class="close"
                  onClick={(e) => {
                    setpopup(false);
                  }}
                >
                  ✖
                </button>
                {/* <img src={logoutimg} alt="cookies-img" /> */}
                <p style={{ marginTop: "20px" }}>
                  Do You Want to Delete the Data?
                </p>
                <div style={{ display: "flex" }}>
                  <button class="accept p-2 m-2" onClick={(e) => confirm()}>
                    Yes!
                  </button>
                  <button
                    class="accept p-2 m-2"
                    onClick={(e) => setpopup(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Property;

// import logo from "../../assets/images/logo4.png";
import logo from "../../assets/images/LogowithLetter.png";
import logoheader from "../../assets/images/logoheader.png";
import "../style/maincompstyle/navbar.scss";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import React, { useState } from "react";
import { sidenav } from "../redux/reducer/counterslice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';

function Navbar(props) {
  const sideactive = useSelector((state) => state.counter.sidebarnav);
  const [navactive, setnavactive] = useState(sideactive);
  const sidebar = useDispatch();
  const logoutpage = useNavigate();
  const toggleOpen = (e) => {
    if (sideactive == false) {
      setnavactive(true);
      sidebar(sidenav(true));
    }
    else {
      setnavactive(!navactive);
      sidebar(sidenav(!navactive));

    }
  };
  const logout = (e) => {
    logoutpage("/login");
    window.location.href = "/login";
    props.popupalert("true");
    props.popuptext("Logout Successfull");
    setTimeout(() => {
      props.popupalert("false");
    }, 4000);

    localStorage.clear();
  };

  return (
    <>
      <div className="header">
        <div
          // className={sideactive ? "header-left" : "header-left-inactive"}
          className="header-left"
        >
          {/* {sideactive ? ( */}
          <div className="dean-heading"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <img
              className="header_img"
              style={{
                width: " 160px",
                height: "60px",
                padding: '4px',
                marginLeft:"-80px",
                borderRadius: "10px"
              }}
              src={logo}
              alt="madkmd"
              onClick={(e)=>{window.location.reload()}}
            ></img>
            {/* <h5 className="dean-head"
              style={{
                fontSize: "19px",
                color: "#176674",
                width: "100%",
                // border: "1px solid",
                fontFamily: "Trajan Pro",
                padding: "14px",
                textAlign: "left",
                // marginTop: "20px",
              }}
            >Dean Investment</h5> */}
          </div>
          {/* ) : (
            <img src={logo}></img>
          )} */}

        </div>
        {/* <div className="navmenu">
          <MenuRoundedIcon
            style={{
              width: "40px",
              height: "30px",
              cursor:"pointer"
            }}
            onClick={toggleOpen}
          />
        </div> */}

        <ul className="nav user-menu">
          <li onClick={(e) => logout()}>
            <span><LogoutIcon
            /></span>
            <span style={{color: "white", fontWeight:"800",marginLeft:"10px"}}>Logout</span>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navbar;

import { createSlice } from "@reduxjs/toolkit";
import { actions } from "react-table";
const initialState = {
  sidebarnav: "true",
  sidebar: "DashBoard",
  loader: "false",
  popupalert: "false",
  editadminuser: "",
  editadminuserid: "",
  edituserdoctorid: "",
  editdoctoruser: "",
  basetimeedit: "",
  basetimeid: "",
  patientid: "",
  speciality: "",
  specialityid: "",
  symptoms: "",
  symptomsid: "",
  pharmacypopup: "",
  pharmacyid: "",
  dvtabs: "About",
  bannerdata: "",
  bannerid: "",

  // Dean Admin
  editpropertyuser: "",
  editpropertyuserid: ""
};
export const counterSlice = createSlice({
  name: "counterSlice",
  initialState,
  reducers: {
    sidenav: (state, action) => {
      state.sidebarnav = action.payload;
      // //console.log(state.sidebarnav)
    },
    sidebar: (state, action) => {
      state.sidebar = action.payload;
      // //console.log(state.sidebar,"hkafgjh")
    },
    loader: (state, action) => {
      state.loaderstate = action.payload;
      // //console.log(state.loaderstate,"kdsjh")
    },
    popupalert: (state, action) => {
      state.popupalert = action.payload;
      // //console.log(state.popupalert,"kdsjh")
    },
    editadminuser: (state, action) => {
      state.edituser = action.payload;
      // //console.log(state.edituser,"kdsjh")
    },
    editadminuserid: (state, action) => {
      state.edituserid = action.payload;
      // //console.log(state.edituserid,"kdsjh")
    },
    editdoctoruser: (state, action) => {
      state.editdoctoruser = action.payload;
      // //console.log(state.edituser,"kdsjh")
    },
    editdoctoruserid: (state, action) => {
      state.edituserdoctorid = action.payload;
      // //console.log(state.edituserdoctorid,"kdsjh")
    },
    basetimeedit: (state, action) => {
      state.basetimeedit = action.payload;
      //  //console.log(state.basetimeedit,"kdsjh")
    },
    basetimeid: (state, action) => {
      state.basetimeid = action.payload;
      //  //console.log(state.basetimeid,"kdsjh")
    },
    patientid: (state, action) => {
      state.patientid = action.payload;
      //  //console.log(state.patientid,"kdsjh")
    },
    symptoms: (state, action) => {
      state.symptoms = action.payload;
      // //console.log(state.edituser,"kdsjh")
    },
    symptomsid: (state, action) => {
      state.symptomsid = action.payload;
    },

    speciality: (state, action) => {
      state.speciality = action.payload;
      // //console.log(state.edituser,"kdsjh")
    },
    specialityid: (state, action) => {
      state.specialityid = action.payload;
    },
    pharmacypopup: (state, action) => {
      state.pharmacypopup = action.payload;
      // //console.log(state.edituser,"kdsjh")
    },
    pharmacyid: (state, action) => {
      state.pharmacyid = action.payload;
      // //console.log(state.edituser,"kdsjh")
    },
    dvtabs: (state, action) => {
      state.dvtabs = action.payload;
      //console.log(state.dvtabs, "kdsjh");
    },
    bannerdata: (state, action) => {
      state.bannerdata = action.payload;
      // //console.log(state.edituser,"kdsjh")
    },
    bannerid: (state, action) => {
      state.bannerid = action.payload;
    },

    bannerid: (state, action) => {
      state.bannerid = action.payload;
    },

    // dean admin
    editpropertyuser: (state, action) => {
      state.editpropertyuser = action.payload;
    },
    editpropertyuserid: (state, action) => {
      state.editpropertyuserid = action.payload;
    }
  },
});

export const {
  sidenav,
  sidebar,
  loader,
  popupalert,
  editadminuser,
  editadminuserid,
  editdoctoruser,
  editdoctoruserid,
  basetimeedit,
  basetimeid,
  patientid,
  speciality,
  specialityid,
  symptoms,
  symptomsid,
  pharmacypopup,
  pharmacyid,
  dvtabs,
  bannerdata,
  bannerid,

  // dean admin
  editpropertyuser,
  editpropertyuserid,

  
} = counterSlice.actions;

export default counterSlice.reducer;

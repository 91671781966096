import React, { useEffect, useState, useRef } from "react";
import "../style/DeanProperty/PropertyForm.scss";
import Button from "@mui/material/Button";
import axios from "axios";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { TextField } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import TagsInput from "react-tagsinput";
import { useSelector, useDispatch, batch } from "react-redux";
import { Multiselect } from "multiselect-react-dropdown";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import CKEditor from "react-ckeditor-component";
// import '../../assets/jquery-ui-1.13.2.custom/jquery-ui.js';
import "jquery-ui-bundle";
// import $ from 'jquery';
import {
  doctorlist,
  spacilitylist,
  presignedurl2,
  propertyedit,
} from "../../text/apidata";
import CreatableSelect from "react-select/creatable";
import Swal from "sweetalert2";

import { propertycreate } from "../../text/apidata";

let u1 = [];
let u2 = [];
let u3 = [];
let u4 = [];

const PropertyEdit = (props) => {
  const sideactive = useSelector((state) => state.counter.sidebarnav);

  const editpropertyuserid = useSelector(
    (state) => state.counter.editpropertyuserid
  );

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const property = useNavigate();

  let craeteproperty = {
    tenure: "",
    property_address: "",
    price: "",
    // agent_phone_number: "",
    bathrooms: "",
    bedrooms: "",
    description: "",
    key_features: "",
    // latitude: "",
    // longitude: "",
    is_active: "",
    media_files: [],
    blueprint: [],
    nearest_stations: [],
    nearest_schools: [],
    purchase_price: "",
    before: [],
    after: [],
    existing_property_price: "",
    project_cost: "",
    gdv: "",
    gain_profit: "",

    d_property_status: "",

    // development_cost: "",
    // empty_column: "",
    // property_value: "",
    // profit: "",
    is_sold: "false",
    development_cost: [],
    empty_column: [],
    profit: [],
    property_return: [],

    purchase_price: [],
    property_value: [],
    dropdown_for_banners: "",
  };

  const [data, setData] = useState({
    tenure: "",
    property_address: "",
    price: "",
    bathrooms: "0",
    bedrooms: "0",
    description: "",
    key_features: "",
    is_active: "",
    media_files: [],
    blueprint: [],
    nearest_stations: [],
    nearest_schools: [],
    purchase_price: "",
    before: [],
    after: [],
    existing_property_price: "",
    project_cost: "",
    gdv: "",
    gain_profit: "",
    d_property_status: "",
    is_sold: "false",
    development_cost: [],
    empty_column: [],
    profit: [],
    property_return: [],

    purchase_price: [],
    property_value: [],
    dropdown_for_banners: "",
  });

  // show data usestate
  const [defalert, setdefalert] = useState(true);
  const [tenure, setTenure] = useState("");
  const [alertpopup, setalertpopup] = useState(false);
  const [errortenure, seterrortenure] = useState("");
  const [errorpropertyaddress, seterrorpropertyaddress] = useState("");
  const [errorprice, seterrorprice] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [errorbathrooms, seterrorbathrooms] = useState("");
  const [errorbedrooms, seterrorbedrooms] = useState("");
  const [errordescription, seterrordescription] = useState("");
  const [bannererror, setbannererror] = useState("");
  const [PropertyTypeError, setPropertyTypeError] = useState("");

  const [errorlatitude, seterrorlatitude] = useState("");
  const [errorlongitude, seterrorlongitude] = useState("");

  const [checked, setChecked] = React.useState(true);
  const [checkboxvalue, setCheckboxvalue] = useState(false);

  const [langerror, setlangerror] = useState(false);
  const [keyfeature, setkeyfeature] = useState([]);
  const [schooltype, setschooltype] = useState("");
  const [schooldistance, setschooldistance] = useState("");
  const [schoolofsted, setschoolofsted] = useState("");

  //edit
  const [CroppImageView, setCroppImageView] = useState(false);
  const [CroppImageViewCount, setCroppImageViewCount] = useState(0);
  const [crop, setCrop] = useState();
  const [imgsrc, setImgsrc] = useState();
  const imgRef = useRef(null);

  const [file, setFile] = useState([]);
  const [colourOptions, setcolourOptions] = useState([
    { value: "Mid-Terraced", label: "Mid-Terraced" },
    { value: "End Terraced", label: "End Terraced" },
    { value: "Semi-Detached", label: "Semi-Detached" },
    { value: "Flat", label: "Flat" },
    { value: "Apartment", label: "Apartment" },
    { value: "Maisonette", label: "Maisonette" },
    { value: "Detached", label: "Detached" },
    { value: "Bungalow", label: "Bungalow" },
  ]);

  // const [file2, setFile2] = useState([]);
  const [imgfile2, setimgfile2] = useState([]);
  const [imgtype2, setimgtype2] = useState([]);
  const [mapurl2, setmapurl2] = useState([]);
  const [preurl2, setpreurl2] = useState([]);
  const [index2, setindex2] = useState(0);

  const [file2, setFile2] = useState([]);
  const [imgfile, setimgfile] = useState([]);
  const [imgtype, setimgtype] = useState([]);
  const [mapurl, setmapurl] = useState([]);
  const [preurl, setpreurl] = useState([]);
  const [index, setindex] = useState(0);
  const [alert, setalert] = useState(false);
  const [alert2, setalert2] = useState(false);

  const [newerror, setnewerror] = useState(false);

  const [beforeimg, setbeforeimg] = useState([]);
  const [beforeimgfile, setbeforeimgfile] = useState([]);
  const [beforeimgtype, setbeforeimgtype] = useState([]);
  const [beforemapurl, setbeforemapurl] = useState([]);
  const [beforepreurl, setbeforepreurl] = useState([]);

  const [afterimg, setafterimg] = useState([]);
  const [afterimgfile, setafterimgfile] = useState([]);
  const [afterimgtype, setafterimgtype] = useState([]);
  const [aftermapurl, setaftermapurl] = useState([]);
  const [afterpreurl, setafterpreurl] = useState([]);

  const validateLongitude0 = (e, index) => {
    // var longitude = e.target.value;
    // const { name, value } = e.target;
    let l1 = [];
    for (let i = 0; i < data.nearest_stations.length; i++) {
      if (index == i) {
        data.nearest_stations[i][e.target.name] = e.target.value;
        l1.push(data.nearest_stations[i]);
      } else {
        l1.push(data.nearest_stations[i]);
      }
    }
    setData((prev) => {
      return {
        ...prev,
        nearest_stations: l1,
      };
    });
    // if (validator.isEmpty(longitude.trim())) {
    //   seterrorlongitude("Longitude Cannot be empty!");
    // } else {
    //   seterrorlongitude("");
    // }
  };

  const onImageLoaded = (image) => {
    // imgRef = image;
    imgRef.current = image;
  };

  const setCompletedCrop = async (c) => {
    const url = await getCroppedImg(imgRef.current, c, "newfile.jpeg");
  };

  const selectedImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImgsrc(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  function getCroppedImg(a, crop, fileName) {
    console.log(crop);
    const image = document.getElementById("localimg");
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    if (crop) {
      canvas.width = crop.width;
      canvas.height = crop.height;
    } else {
      canvas.width = image.width;
      canvas.height = image.height;
    }
    const ctx = canvas.getContext("2d");
    if (crop) {
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
    } else {
      ctx.drawImage(
        image,
        scaleX,
        scaleY,
        image.width * scaleX,
        image.height * scaleY,
        0,
        0,
        image.width,
        image.height
      );
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        setalertpopup(true);

        const croppedFile = new File([blob], "croppedimg.jpg", {
          type: "image/jpeg",
        });
        if (CroppImageViewCount == 1) {
          setFile((oldArray) => [
            ...oldArray,
            String(URL.createObjectURL(croppedFile)),
          ]);
          setimgfile((oldArray) => [...oldArray, croppedFile]);
          setimgtype((oldArray) => [...oldArray, croppedFile.type]);
          var val = Math.floor(1000 + Math.random() * 9000);
          var name = {
            multiple_files: [
              {
                ["filename"]:
                  "assestsfortesting" +
                  "/" +
                  String(val) +
                  String(croppedFile.name).replace(/ +/g, ""),
                ["file_type"]: croppedFile.type,
              },
            ],
          };

          axios({
            method: "post",
            url: presignedurl2,
            data: name,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token") + "",
            },
          })
            .then((res) => {
              setpreurl((oldArray) => [
                ...oldArray,
                String(res.data.data).split("?")[0],
              ]);
              u1.push(String(res.data.data).split("?")[0]);
              setData({
                ...data,
                media_files: u1,
              });
              ////console.log(u1, "444444");
              ////console.log(data.media_files, "5555555");

              setmapurl((oldArray) => [
                ...oldArray,
                {
                  url: String(res.data.data),
                  type: croppedFile.type,
                  file: croppedFile,
                },
              ]);
            })
            .catch((error) => {
              ////console.log(error);
              console.log(error);
              if (error.response.status == "401") {
                Swal.fire({
                  title: "Token Expired Login Again!",
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    property("/login");
                    localStorage.clear();
                    // window.location.reload();
                  }
                });
              }
            });
        } else if (CroppImageViewCount == 2) {
          setFile2((oldArray) => [
            ...oldArray,
            String(URL.createObjectURL(croppedFile)),
          ]);

          setimgfile2((oldArray) => [...oldArray, croppedFile]);
          setimgtype2((oldArray) => [...oldArray, croppedFile.type]);
          var val = Math.floor(1000 + Math.random() * 9000);
          var name2 = {
            multiple_files: [
              {
                ["filename"]:
                  "assestsfortesting" +
                  "/" +
                  String(val) +
                  String(croppedFile.name).replace(/ +/g, ""),
                ["file_type"]: croppedFile.type,
              },
            ],
          };

          axios({
            method: "post",
            url: presignedurl2,
            data: name2,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token") + "",
            },
          })
            .then((res) => {
              setpreurl2((oldArray) => [
                ...oldArray,
                String(res.data.data).split("?")[0],
              ]);
              ////console.log(u1, "2222222");
              u2.push(String(res.data.data).split("?")[0]);
              ////console.log(u1, "33333");
              setData({
                ...data,
                blueprint: u2,
              });
              ////console.log(u1, "444444");
              ////console.log(data.media_files, "5555555");

              setmapurl2((oldArray) => [
                ...oldArray,
                {
                  url: String(res.data.data),
                  type: croppedFile.type,
                  file: croppedFile,
                },
              ]);
            })
            .catch((error) => {
              ////console.log(error);
              console.log(error);
              if (error.response.status == "401") {
                Swal.fire({
                  title: "Token Expired Login Again!",
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    property("/login");
                    localStorage.clear();
                    // window.location.reload();
                  }
                });
              }
            });
        } else if (CroppImageViewCount == 4) {
          setafterimg((oldArray) => [
            ...oldArray,
            String(URL.createObjectURL(croppedFile)),
          ]);
          setafterimgfile((oldArray) => [...oldArray, croppedFile]);
          setafterimgtype((oldArray) => [...oldArray, croppedFile.type]);
          var val = Math.floor(1000 + Math.random() * 9000);
          var name = {
            multiple_files: [
              {
                ["filename"]:
                  "assestsfortesting" +
                  "/" +
                  String(val) +
                  String(croppedFile.name).replace(/ +/g, ""),
                ["file_type"]: croppedFile.type,
              },
            ],
          };

          axios({
            method: "post",
            url: presignedurl2,
            data: name,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token") + "",
            },
          })
            .then((res) => {
              setafterpreurl((oldArray) => [
                ...oldArray,
                String(res.data.data).split("?")[0],
              ]);
              u4.push(String(res.data.data).split("?")[0]);
              setData({
                ...data,
                after: u4,
              });
              ////console.log(u1, "444444");
              ////console.log(data.blueprint, "5555555");

              setaftermapurl((oldArray) => [
                ...oldArray,
                {
                  url: String(res.data.data),
                  type: croppedFile.type,
                  file: croppedFile,
                },
              ]);
            })
            .catch((error) => {
              ////console.log(error);
              console.log(error);
              if (error.response.status == "401") {
                Swal.fire({
                  title: "Token Expired Login Again!",
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    property("/login");
                    localStorage.clear();
                    // window.location.reload();
                  }
                });
              }
            });
        } else if (CroppImageViewCount == 3) {
          setbeforeimg((oldArray) => [
            ...oldArray,
            String(URL.createObjectURL(croppedFile)),
          ]);
          setbeforeimgfile((oldArray) => [...oldArray, croppedFile]);
          setbeforeimgtype((oldArray) => [...oldArray, croppedFile.type]);
          var val = Math.floor(1000 + Math.random() * 9000);

          var name = {
            multiple_files: [
              {
                ["filename"]:
                  "assestsfortesting" +
                  "/" +
                  String(val) +
                  String(croppedFile.name).replace(/ +/g, ""),
                ["file_type"]: croppedFile.type,
              },
            ],
          };

          axios({
            method: "post",
            url: presignedurl2,
            data: name,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token") + "",
            },
          })
            .then((res) => {
              setbeforepreurl((oldArray) => [
                ...oldArray,
                String(res.data.data).split("?")[0],
              ]);
              u3.push(String(res.data.data).split("?")[0]);
              setData({
                ...data,
                before: u3,
              });
              ////console.log(u1, "444444");
              ////console.log(data.blueprint, "5555555");

              setbeforemapurl((oldArray) => [
                ...oldArray,
                {
                  url: String(res.data.data),
                  type: croppedFile.type,
                  file: croppedFile,
                },
              ]);
            })
            .catch((error) => {
              ////console.log(error);
              console.log(error);
              if (error.response.status == "401") {
                Swal.fire({
                  title: "Token Expired Login Again!",
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    property("/login");
                    localStorage.clear();
                    // window.location.reload();
                  }
                });
              }
            });
        }
        setCroppImageView(false);
        // ////console.log(croppedFile);
        blob.name = fileName;
        // window.URL.revokeObjectURL(this.fileUrl);
        let fileUrl = window.URL.createObjectURL(blob);
        ////console.log(fileUrl);
        resolve(fileUrl);
      }, "image/jpeg");
    });
  }

  const validateLongitude1 = (e, index) => {
    setalertpopup(true);

    // var longitude = e.target.value;
    // const { name, value } = e.target;
    let l1 = [];
    for (let i = 0; i < data.nearest_schools.length; i++) {
      if (index == i) {
        data.nearest_schools[i][e.target.name] = e.target.value;
        l1.push(data.nearest_schools[i]);
      } else {
        l1.push(data.nearest_schools[i]);
      }
    }
    setData((prev) => {
      return {
        ...prev,
        nearest_schools: l1,
      };
    });
    // if (validator.isEmpty(longitude.trim())) {
    //   seterrorlongitude("Longitude Cannot be empty!");
    // } else {
    //   seterrorlongitude("");
    // }
  };

  const validateLongitude6 = (e, index) => {
    setalertpopup(true);

    // var longitude = e.target.value;
    // const { name, value } = e.target;
    var input = e.target.value;
    var numericInput = input.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    // ////console.log(numericInput);
    e.target.value = numericInput;
    // ////console.log(numericInput, e.target.value);
    let val = new Intl.NumberFormat("en-GB").format(numericInput).toString();
    // ////console.log(val);
    let l1 = [];
    for (let i = 0; i < data.purchase_price.length; i++) {
      if (index == i) {
        data.purchase_price[i][e.target.name] = val;
        l1.push(data.purchase_price[i]);
      } else {
        l1.push(data.purchase_price[i]);
      }
    }
    setData((prev) => {
      return {
        ...prev,
        purchase_price: l1,
      };
    });
    // if (validator.isEmpty(longitude.trim())) {
    //   seterrorlongitude("Longitude Cannot be empty!");
    // } else {
    //   seterrorlongitude("");
    // }
  };

  const validateLongitude2 = (e, index) => {
    setalertpopup(true);

    // var longitude = e.target.value;
    // const { name, value } = e.target;
    var input = e.target.value;
    var numericInput = input.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    // ////console.log(numericInput);
    e.target.value = numericInput;
    // ////console.log(numericInput, e.target.value);
    let val = new Intl.NumberFormat("en-GB").format(numericInput).toString();
    // ////console.log(val);
    let l1 = [];
    for (let i = 0; i < data.development_cost.length; i++) {
      if (index == i) {
        data.development_cost[i][e.target.name] = val;
        l1.push(data.development_cost[i]);
      } else {
        l1.push(data.development_cost[i]);
      }
    }
    setData((prev) => {
      return {
        ...prev,
        development_cost: l1,
      };
    });
    // if (validator.isEmpty(longitude.trim())) {
    //   seterrorlongitude("Longitude Cannot be empty!");
    // } else {
    //   seterrorlongitude("");
    // }
  };

  const validateLongitude3 = (e, index) => {
    setalertpopup(true);

    // var longitude = e.target.value;
    // const { name, value } = e.target;
    var input = e.target.value;
    var numericInput = input.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    // ////console.log(numericInput);
    e.target.value = numericInput;
    // ////console.log(numericInput, e.target.value);
    let val = new Intl.NumberFormat("en-GB").format(numericInput).toString();
    // ////console.log(val);
    let l1 = [];
    for (let i = 0; i < data.property_value.length; i++) {
      if (index == i) {
        data.property_value[i][e.target.name] = val;
        l1.push(data.property_value[i]);
      } else {
        l1.push(data.property_value[i]);
      }
    }
    setData((prev) => {
      return {
        ...prev,
        property_value: l1,
      };
    });
    // if (validator.isEmpty(longitude.trim())) {
    //   seterrorlongitude("Longitude Cannot be empty!");
    // } else {
    //   seterrorlongitude("");
    // }
  };
  const errorfunction = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    });
  };

  const validateLongitude4 = (e, index) => {
    setalertpopup(true);

    // var longitude = e.target.value;
    // const { name, value } = e.target;
    var input = e.target.value;
    var numericInput = input.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    // ////console.log(numericInput);
    e.target.value = numericInput;
    // ////console.log(numericInput, e.target.value);
    let val = new Intl.NumberFormat("en-GB").format(numericInput).toString();
    // ////console.log(val);
    let l1 = [];
    for (let i = 0; i < data.profit.length; i++) {
      if (index == i) {
        data.profit[i][e.target.name] = val;
        l1.push(data.profit[i]);
      } else {
        l1.push(data.profit[i]);
      }
    }
    setData((prev) => {
      return {
        ...prev,
        profit: l1,
      };
    });
    // if (validator.isEmpty(longitude.trim())) {
    //   seterrorlongitude("Longitude Cannot be empty!");
    // } else {
    //   seterrorlongitude("");
    // }
  };

  const validateLongitude5 = (e, index) => {
    // var longitude = e.target.value;
    setalertpopup(true);

    // const { name, value } = e.target;
    var input = e.target.value;
    var numericInput = input.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    // ////console.log(numericInput);
    e.target.value = numericInput;
    // ////console.log(numericInput, e.target.value);
    let val = new Intl.NumberFormat("en-GB").format(numericInput).toString();
    // ////console.log(val);
    let l1 = [];
    for (let i = 0; i < data.empty_column.length; i++) {
      if (index == i) {
        data.empty_column[i][e.target.name] = val;
        l1.push(data.empty_column[i]);
      } else {
        l1.push(data.empty_column[i]);
      }
    }
    setData((prev) => {
      return {
        ...prev,
        empty_column: l1,
      };
    });
    // if (validator.isEmpty(longitude.trim())) {
    //   seterrorlongitude("Longitude Cannot be empty!");
    // } else {
    //   seterrorlongitude("");
    // }
  };

  const validatePropertyReturn = (e, index) => {
    setalertpopup(true);

    // var longitude = e.target.value;
    // const { name, value } = e.target;
    var input = e.target.value;
    var numericInput = input.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    // ////console.log(numericInput);
    e.target.value = numericInput;
    // ////console.log(numericInput, e.target.value);
    let val = new Intl.NumberFormat("en-GB").format(numericInput).toString();
    // ////console.log(val);
    let l1 = [];
    for (let i = 0; i < data?.property_return?.length; i++) {
      if (index == i) {
        data.property_return[i][e.target.name] = val;
        l1.push(data.property_return[i]);
      } else {
        l1.push(data.property_return[i]);
      }
    }
    setData((prev) => {
      return {
        ...prev,
        property_return: l1,
      };
    });
    // if (validator.isEmpty(longitude.trim())) {
    //   seterrorlongitude("Longitude Cannot be empty!");
    // } else {
    //   seterrorlongitude("");
    // }
  };

  const [list, setlist] = useState([1]);
  const addItemToList = () => {
    setData((prev) => {
      return {
        ...prev,
        nearest_schools: [
          ...data.nearest_schools,
          {
            school: "",
            type: "",
            Ofsted: "",
          },
        ],
      };
    });
  };
  const removeItemFromList = (index) => {
    setalertpopup(true);

    let l1 = [];
    for (let i = 0; i < data.nearest_schools.length; i++) {
      if (index != i) {
        l1.push(data.nearest_schools[i]);
      }
    }
    setData((prev) => {
      return {
        ...prev,
        nearest_schools: l1,
      };
    });
  };

  // const [station, setstation] = useState([1]);
  const addItemToList2 = () => {
    setData((prev) => {
      return {
        ...prev,
        nearest_stations: [
          ...data.nearest_stations,
          {
            station: "",
            station_name: "",
          },
        ],
      };
    });
  };
  const removeItemFromList2 = (index) => {
    setalertpopup(true);

    // const updatedList2 = station.filter((item, i) => i !== index);
    // setstation(updatedList2);
    let l1 = [];
    for (let i = 0; i < data.nearest_stations.length; i++) {
      if (index != i) {
        l1.push(data.nearest_stations[i]);
      }
    }
    setData((prev) => {
      return {
        ...prev,
        nearest_stations: l1,
      };
    });
  };

  // const [developmentcost, setdevelopmentcost] = useState([1]);
  const addItemToList3 = () => {
    // setdevelopmentcost([...developmentcost, developmentcost.length + 1]);
    setData((prev) => {
      return {
        ...prev,
        development_cost: [
          ...data.development_cost,
          {
            Loan: "",
            your_investment: "",
            Total: "",
          },
        ],
      };
    });
  };
  const removeItemFromList3 = (index) => {
    // const updatedList3 = developmentcost.filter((item, i) => i !== index);
    // setdevelopmentcost(updatedList3);
    let l1 = [];
    for (let i = 0; i < data.development_cost.length; i++) {
      if (index != i) {
        l1.push(data.development_cost[i]);
      }
    }
    setData((prev) => {
      return {
        ...prev,
        development_cost: l1,
      };
    });
  };

  // const [empty, setempty] = useState([1]);
  const addItemToList4 = () => {
    // setempty([...empty, empty.length + 1]);
    setData((prev) => {
      return {
        ...prev,
        empty_column: [
          ...data.empty_column,
          {
            Loan: "",
            your_investment: "",
            Total: "",
          },
        ],
      };
    });
  };
  const removeItemFromList4 = (index) => {
    // const updatedList4 = empty.filter((item, i) => i !== index);
    // setempty(updatedList4);
    let l1 = [];
    for (let i = 0; i < data.empty_column.length; i++) {
      if (index != i) {
        l1.push(data.empty_column[i]);
      }
    }
    setData((prev) => {
      return {
        ...prev,
        empty_column: l1,
      };
    });
  };

  // const [profit, setprofit] = useState([1]);
  const addItemToList5 = () => {
    // setprofit([...profit, profit.length + 1]);
    setData((prev) => {
      return {
        ...prev,
        profit: [
          ...data.profit,
          {
            Loan: "",
            your_investment: "",
            Total: "",
          },
        ],
      };
    });
  };
  const removeItemFromList5 = (index) => {
    // const updatedList5 = profit.filter((item, i) => i !== index);
    // setprofit(updatedList5);
    let l1 = [];
    for (let i = 0; i < data.profit.length; i++) {
      if (index != i) {
        l1.push(data.profit[i]);
      }
    }
    setData((prev) => {
      return {
        ...prev,
        profit: l1,
      };
    });
  };

  // const [propertyvalue, setpropertyvalue] = useState([1]);
  const addItemToList6 = () => {
    // setpropertyvalue([...propertyvalue, propertyvalue.length + 1]);
    setData((prev) => {
      return {
        ...prev,
        property_value: [
          ...data.property_value,
          {
            Loan: "",
            your_investment: "",
            Total: "",
          },
        ],
      };
    });
  };
  const removeItemFromList6 = (index) => {
    // const updatedList6 = propertyvalue.filter((item, i) => i !== index);
    // setpropertyvalue(updatedList6);
    let l1 = [];
    for (let i = 0; i < data.property_value.length; i++) {
      if (index != i) {
        l1.push(data.property_value[i]);
      }
    }
    setData((prev) => {
      return {
        ...prev,
        property_value: l1,
      };
    });
  };

  // const [purchaseprice, setpurchaseprice] = useState([1]);
  const addItemToList7 = () => {
    // setpurchaseprice([...purchaseprice, purchaseprice.length + 1]);
    setData((prev) => {
      return {
        ...prev,
        purchase_price: [
          ...data.purchase_price,
          {
            Loan: "",
            your_investment: "",
            Total: "",
          },
        ],
      };
    });
  };
  const removeItemFromList7 = (index) => {
    // const updatedList7 = purchaseprice.filter((item, i) => i !== index);
    // setpurchaseprice(updatedList7);
    let l1 = [];
    for (let i = 0; i < data.purchase_price.length; i++) {
      if (index != i) {
        l1.push(data.purchase_price[i]);
      }
    }
    setData((prev) => {
      return {
        ...prev,
        purchase_price: l1,
      };
    });
  };

  const validateTenure = (e) => {
    setalertpopup(true);

    var tenure = e.target.value;
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    if (validator.isEmpty(tenure.trim())) {
      seterrortenure("Tenure Cannot be empty!");
    } else {
      seterrortenure("");
    }
  };

  const validatePropAddress = (e) => {
    setalertpopup(true);

    var property_address = e.target.value;
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    if (validator.isEmpty(property_address.trim())) {
      seterrorpropertyaddress("Property Address be empty!");
    } else {
      seterrorpropertyaddress("");
    }
  };

  const validatePrice = async (e) => {
    setalertpopup(true);

    // var price = Number(e.target.value.replace(/\,/g, ""));
    const { name, value } = e.target;
    var input = e.target.value;
    var numericInput = input.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    ////console.log(numericInput);
    e.target.value = numericInput;
    ////console.log(numericInput, e.target.value);
    let val = new Intl.NumberFormat("en-GB").format(numericInput).toString();
    ////console.log(val);
    setData({
      ...data,
      [name]: val,
    });

    if (validator.isEmpty(String(numericInput).trim())) {
      seterrorprice("Price Cannot be empty!");
    } else {
      seterrorprice("");
    }
  };

  const validatePhone = (e) => {
    var agent_phone_number = e.target.value;
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    if (validator.isEmpty(agent_phone_number)) {
      setPhoneError("Phone Number Cannot be empty!");
    } else if (
      validator.isMobilePhone(agent_phone_number) &&
      validator.isLength(e.target.value, { min: 10, max: 11 })
    ) {
      setPhoneError("");
    } else {
      setPhoneError("Enter Valid Phone Number!");
    }
  };
  const [bathroom, setbathroom] = useState("");

  const [bedrooms, setbedrooms] = useState("");

  // const validateBathrooms = (e) => {
  //   setalertpopup(true);
  //   setbathroom(e.target.value);
  //   var bathrooms = e.target.value;
  //   const { name, value } = e.target;
  //   setData({
  //     ...data,
  //     [name]: value,
  //   });
  //   if (validator.isEmpty(bathrooms.trim())) {
  //     seterrorbathrooms("Bathrooms Field Required");
  //   } else {
  //     seterrorbathrooms("");
  //   }
  // };
  const validateBathrooms = (e) => {
    setalertpopup(true);
    
    const bathrooms = e.target.value.trim(); // Trim any whitespace
    
    // Update state with the input value
    setData({
      ...data,
      [e.target.name]: bathrooms, // Update state directly with the trimmed value
    });
  
    // Check if the input is empty
    if (validator.isEmpty(bathrooms)) {
      setbathroom("0"); // If empty, set bathrooms to 0
      // seterrorbathrooms("Bathrooms Field Required");
      setData({
        ...data,
        [e.target.name]: "0", // Update state directly with the trimmed value
      });
    } else {
      setbathroom(bathrooms); // Set bathrooms to the provided value
      seterrorbathrooms("");
    }
  };
  
  // const validateBedrooms = (e) => {
  //   setalertpopup(true);
  //   setbedrooms(e.target.value);

  //   var bedrooms = e.target.value;
  //   const { name, value } = e.target;
  //   setData({
  //     ...data,
  //     [name]: value,
  //   });
  //   if (validator.isEmpty(bedrooms.trim())) {
  //     seterrorbedrooms("Bedroom Field Required");
  //   } else {
  //     seterrorbedrooms("");
  //   }
  // };
  const validateBedrooms = (e) => {
    setalertpopup(true);
    
    const bedroomsValue = e.target.value.trim(); // Trim any whitespace
    
    // Update state with the input value
    setData({
      ...data,
      [e.target.name]: bedroomsValue,
    });
  
    // Check if the input is empty
    if (validator.isEmpty(bedroomsValue)) {
      setbedrooms("0"); // If empty, set bedrooms to 0
      // seterrorbedrooms("Bedroom Field Required");
      setData({
        ...data,
        [e.target.name]: "0",
      });
    } else {
      setbedrooms(bedroomsValue); // Set bedrooms to the provided value
      seterrorbedrooms("");
    }
  };
  

  const validateDescription = (e) => {
    setalertpopup(true);

    var description = e.editor.getData();
    // const { name, value } = e.target;
    // setData({
    //   ...data,
    //   'description': e.editor.getData(),
    // });
    setData((prev) => {
      return {
        ...prev,
        description: e.editor.getData(),
      };
    });

    if (validator.isEmpty(description.trim())) {
      seterrordescription("Description Address cannot be empty!");
    } else {
      seterrordescription("");
    }
  };
  const validateofferdisplay = (e) => {
    var offerdisplay = e.target.value;
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const validateLatitude = (e) => {
    var latitude = e.target.value;
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    if (validator.isEmpty(latitude.trim())) {
      seterrorlatitude("Latitude Cannot be empty!");
    } else {
      seterrorlatitude("");
    }
  };

  const validateLongitude = (e, index) => {
    setalertpopup(true);

    // ////console.log(e.target.value, e.target.name);
    // var longitude = e.target.value;
    // const { name, value } = e.target;
    // setData({
    //   ...data,
    //   [e.target.name]: longitude,
    // });
    ////console.log(e);
    let l1 = [];
    for (let i = 0; i < data.nearest_stations.length; i++) {
      if (index == i) {
        data.nearest_stations[i][e.target.name] = e.target.value;
        l1.push(data.nearest_stations[i]);
      } else {
        l1.push(data.nearest_stations[i]);
      }
    }
    setData((prev) => {
      return {
        ...prev,
        nearest_stations: l1,
      };
    });
    // if (validator.isEmpty(longitude.trim())) {
    //   seterrorlongitude("Longitude Cannot be empty!");
    // } else {
    //   seterrorlongitude("");
    // }
  };

  const keyfeaturefield = (e) => {
    setalertpopup(true);

    setlangerror(false);
    const keyfeatures = "key_features";
    let l3 = [];
    e.length > 0 &&
      e.map((ele) => {
        l3.push(ele);
      });
    setkeyfeature(l3);

    setData({
      ...data,
      [keyfeatures]: l3,
    });

    // seterrorfees(false);
  };

  const validateSchoolType = (e) => {
    var type = e.target.value;
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    if (validator.isEmpty(type.trim())) {
      setschooltype("School Type cannot be empty!");
    } else {
      setschooltype("");
    }
  };

  const validateSchoolDistace = (e) => {
    var distance = e.target.value;
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    if (validator.isEmpty(distance.trim())) {
      setschooldistance("School Distance cannot be empty!");
    } else {
      setschooldistance("");
    }
  };

  const validateSchoolOfsted = (e) => {
    var ofsted = e.target.value;
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    if (validator.isEmpty(ofsted.trim())) {
      setschooldistance("School ofsted cannot be empty!");
    } else {
      setschooldistance("");
    }
  };

  const checkbox = (e) => {
    setalertpopup(true);

    // ////console.log(e, e.target.checked);
    setChecked(e.target.checked);
    setData({
      ...data,
      is_active: e.target.checked,
    });
    setCheckboxvalue((current) => !current);

    // ////console.log(checkboxvalue, "checkboxvalue");
  };

  const newusercreat = (e) => {
    e.preventDefault();
    // if(data.bathrooms == ""){
    //   setData({
    //     ...data,
    //     bathrooms:"0"
    //   })
    // }
    // if(data.bedrooms != ""){
    //   setData({
    //     ...data,
    //     bedrooms:"0"
    //   })
    // }
    console.log(data)
    if (data.d_property_status != "0" ) {
      if (
        data.tenure != "" &&
        data.property_address != "" &&
        data.price != "" &&
        data.bathrooms != "" &&
        data.bedrooms != "" &&
        data.description != "" &&
        data.media_files.length != 0
      ) {
        const div = document.getElementsByClassName("ui-state-default");
        let array = data.media_files;
        let indexlist = [];
        for (let i = 0; i < div.length; i++) {
          indexlist.push(div[i].getAttribute("id"));
        }
        let newarr = indexlist.map((index) => array[index]);
        ////console.log(newarr);
        u1 = newarr;
        setData({
          ...data,
          media_files: newarr,
        });

        const div2 = document.getElementsByClassName("ui-state-default2");
        let array2 = data.blueprint;
        let indexlist2 = [];
        for (let i = 0; i < div2.length; i++) {
          indexlist2.push(div2[i].getAttribute("id"));
        }
        let newarr2 = indexlist2.map((index) => array2[index]);
        ////console.log(newarr2);
        u2 = newarr2;
        setData({
          ...data,
          blueprint: newarr2,
        });
        craeteproperty = data;
        craeteproperty.media_files = newarr;
        craeteproperty.blueprint = newarr2;

        async function updated() {
          Swal.fire({
            title: "Are you sure?",
            text: "You want to update the data!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Update it!",
          }).then((result) => {
            ////console.log(result);
            if (result.isConfirmed) {
              props.loaderchange("true");
              ////console.log(mapurl2)
              if (mapurl2.length > 0) {
                mapurl2.forEach((ele, index) => {
                  ////console.log(ele)
                  async function uploadimage3() {
                    setTimeout(async () => {
                      const resp = await fetch(ele.url, {
                        method: "PUT",
                        body: ele.file,
                        headers: {
                          // "Authorization": "Bearer " + localStorage.getItem("token") + "",
                          "Content-Type": ele.type, // Remove the space
                          "X-Amz-ACL": "public-read",
                        },
                      })
                        .then((res) => {
                          if (index + 1 == mapurl2.length) {
                            //debugger;
                            if (mapurl.length > 0) {
                              mapurl.forEach((ele, index1) => {
                                async function uploadimage() {
                                  const resp1 = await fetch(ele.url, {
                                    method: "PUT",
                                    body: ele.file,
                                    headers: {
                                      // "Authorization": "Bearer " + localStorage.getItem("token") + "",
                                      "Content-Type": ele.type,
                                      "X-Amz-ACL": "public-read",
                                    },
                                  }).then((res) => {
                                    if (index1 + 1 == mapurl.length) {
                                      axios({
                                        method: "put",
                                        data: craeteproperty,
                                        url:
                                          propertyedit +
                                          editpropertyuserid +
                                          "/",
                                        headers: {
                                          Authorization:
                                            "Bearer " +
                                            localStorage.getItem("token") +
                                            "",
                                        },
                                      })
                                        .then((res) => {
                                          // //debugger;

                                          if (res.status == 200) {
                                            Swal.fire(
                                              "Updated!",
                                              "Your data has been updated.",
                                              "success"
                                            );

                                            property("/");
                                            props.loaderchange("false");
                                          }
                                        })
                                        .catch((error) => {
                                          if (error.response.status == "401") {
                                            Swal.fire({
                                              title:
                                                "Token Expired Login Again!",
                                              showClass: {
                                                popup:
                                                  "animate__animated animate__fadeInDown",
                                              },
                                              hideClass: {
                                                popup:
                                                  "animate__animated animate__fadeOutUp",
                                              },
                                            }).then((result) => {
                                              if (result.isConfirmed) {
                                                console.log("11111");
                                                setdefalert(false);

                                                property("/login");
                                                localStorage.clear();
                                                // window.location.reload();
                                              }
                                            });
                                          }

                                          props.loaderchange("false");
                                        });
                                    }

                                    ////console.log(res);
                                    // props.loaderchange("false");
                                  });
                                }
                                uploadimage();
                              });
                            } else {
                              axios({
                                method: "put",
                                data: craeteproperty,
                                url: propertyedit + editpropertyuserid + "/",
                                headers: {
                                  Authorization:
                                    "Bearer " +
                                    localStorage.getItem("token") +
                                    "",
                                },
                              })
                                .then((res) => {
                                  // //debugger;

                                  if (res.status == 200) {
                                    Swal.fire(
                                      "Updated!",
                                      "Your data has been updated.",
                                      "success"
                                    );

                                    property("/");
                                    props.loaderchange("false");
                                  }
                                })
                                .catch((error) => {
                                  props.loaderchange("false");
                                  if (error.response.status == "401") {
                                    Swal.fire({
                                      title: "Token Expired Login Again!",
                                      showClass: {
                                        popup:
                                          "animate__animated animate__fadeInDown",
                                      },
                                      hideClass: {
                                        popup:
                                          "animate__animated animate__fadeOutUp",
                                      },
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        console.log("11111");
                                        setdefalert(false);

                                        property("/login");
                                        localStorage.clear();
                                        // window.location.reload();
                                      }
                                    });
                                  } else {
                                    errorfunction();
                                  }

                                  props.loaderchange("false");
                                });
                            }
                          }
                        })
                        .catch((err) => {
                          props.loaderchange("false");
                        });
                    }, 200 + index * 100);
                  }

                  uploadimage3();
                });
              } else if (mapurl.length > 0) {
                mapurl.forEach((ele, index1) => {
                  async function uploadimage() {
                    const resp1 = await fetch(ele.url, {
                      method: "PUT",
                      body: ele.file,
                      headers: {
                        // "Authorization": "Bearer " + localStorage.getItem("token") + "",
                        "Content-Type": ele.type,
                        "X-Amz-ACL": "public-read",
                      },
                    })
                      .then((res) => {
                        if (index1 + 1 == mapurl.length) {
                          axios({
                            method: "put",
                            data: craeteproperty,
                            url: propertyedit + editpropertyuserid + "/",
                            headers: {
                              Authorization:
                                "Bearer " + localStorage.getItem("token") + "",
                            },
                          })
                            .then((res) => {
                              // //debugger;

                              if (res.status == 200) {
                                Swal.fire(
                                  "Updated!",
                                  "Your data has been updated.",
                                  "success"
                                );

                                property("/");
                                // setTimeout(() => {
                                //   window.location.reload();
                                // }, 300);
                                // editdoctor(editdoctoruser(""));
                                // props.popupalert("true");
                                // props.popuptext("Doctor Details Updated Successfully");
                                // setTimeout(() => {
                                //   props.popupalert("false");
                                // }, 2000);
                                // props.loaderchange("false");
                              }
                            })
                            .catch((error) => {
                              ////console.log(error);
                              // props.popupalert("true");
                              // props.popuptext(error.response.data.status.message);
                              // setTimeout(() => {
                              props.loaderchange("false");
                              // }, 2000);
                              if (error.response.status == "401") {
                                Swal.fire({
                                  title: "Token Expired Login Again!",
                                  showClass: {
                                    popup:
                                      "animate__animated animate__fadeInDown",
                                  },
                                  hideClass: {
                                    popup:
                                      "animate__animated animate__fadeOutUp",
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    console.log("11111");
                                    setdefalert(false);

                                    property("/login");
                                    localStorage.clear();
                                    // window.location.reload();
                                  }
                                });
                              } else {
                                errorfunction();
                              }

                              props.loaderchange("false");
                            });
                        }
                        ////console.log(res);
                        // props.loaderchange("false");
                      })

                      .catch((err) => {
                        ////console.log(err);
                        props.loaderchange("false");
                        return null;
                      });
                  }
                  uploadimage();
                });
              } else {
                axios({
                  method: "put",
                  data: craeteproperty,
                  url: propertyedit + editpropertyuserid + "/",
                  headers: {
                    Authorization:
                      "Bearer " + localStorage.getItem("token") + "",
                  },
                })
                  .then((res) => {
                    // //debugger;

                    if (res.status == 200) {
                      Swal.fire(
                        "Updated!",
                        "Your data has been updated.",
                        "success"
                      );

                      property("/");
                      // setTimeout(() => {
                      //   window.location.reload();
                      // }, 300);
                      // editdoctor(editdoctoruser(""));
                      // props.popupalert("true");
                      // props.popuptext("Doctor Details Updated Successfully");
                      // setTimeout(() => {
                      //   props.popupalert("false");
                      // }, 2000);
                      props.loaderchange("false");
                    }
                  })
                  .catch((error) => {
                    ////console.log(error);
                    // props.popupalert("true");
                    // props.popuptext(error.response.data.status.message);
                    // setTimeout(() => {
                    // props.popupalert("false");
                    // }, 2000);
                    props.loaderchange("false");
                    if (error.response.status == "401") {
                      Swal.fire({
                        title: "Token Expired Login Again!",
                        showClass: {
                          popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                          popup: "animate__animated animate__fadeOutUp",
                        },
                      }).then((result) => {
                        if (result.isConfirmed) {
                          console.log("11111");
                          setdefalert(false);

                          property("/login");
                          localStorage.clear();
                          // window.location.reload();
                        }
                      });
                    } else {
                      errorfunction();
                    }

                    props.loaderchange("false");
                  });
              }

              // beforemapurl.forEach((ele) => {
              //   async function uploadimage3() {
              //     const resp = await fetch(ele.url, {
              //       method: "PUT",
              //       body: ele.file,
              //       headers: {
              //         // "Authorization": "Bearer " + localStorage.getItem("token") + "",
              //         "Content-Type": ele.type,
              //         "X-Amz-ACL": "public-read",
              //       },
              //     })
              //       .then((res) => {

              //         // ////console.log(res);
              //         // props.loaderchange("false");
              //       })

              //       .catch((err) => {
              //         ////console.log(err);
              //         props.loaderchange("false");
              //         return null;
              //       });
              //   }
              //   uploadimage3();
              // });

              // aftermapurl.forEach((ele) => {
              //   async function uploadimage4() {
              //     const resp = await fetch(ele.url, {
              //       method: "PUT",
              //       body: ele.file,
              //       headers: {
              //         // "Authorization": "Bearer " + localStorage.getItem("token") + "",
              //         "Content-Type": ele.type,
              //         "X-Amz-ACL": "public-read",
              //       },
              //     })
              //       .then((res) => {
              //         // ////console.log(res);
              //         // props.loaderchange("false");
              //       })

              //       .catch((err) => {
              //         ////console.log(err);
              //         props.loaderchange("false");
              //         return null;
              //       });
              //   }
              //   uploadimage4();
              // });
              // setTimeout(() => {
              //   axios({
              //     method: "put",
              //     data: craeteproperty,
              //     url: propertyedit + editpropertyuserid + "/",
              //     headers: {
              //       Authorization:
              //         "Bearer " + localStorage.getItem("token") + "",
              //     },
              //   })
              //     .then((res) => {
              //       // //debugger;

              //       if (res.status == 200) {
              //         Swal.fire(
              //           "Updated!",
              //           "Your data has been updated.",
              //           "success"
              //         );

              //         // property("/");
              //         setTimeout(() => {
              //           window.location.reload();
              //         }, 300);
              //         // editdoctor(editdoctoruser(""));
              //         // props.popupalert("true");
              //         // props.popuptext("Doctor Details Updated Successfully");
              //         // setTimeout(() => {
              //         //   props.popupalert("false");
              //         // }, 2000);
              //         props.loaderchange("false");
              //       }
              //     })
              //     .catch((error) => {
              //       ////console.log(error);
              //       // props.popupalert("true");
              //       // props.popuptext(error.response.data.status.message);
              //       // setTimeout(() => {
              //       // props.popupalert("false");
              //       // }, 2000);
              //       if (error.response.status == "401") {
              //         Swal.fire({
              //           title: 'Token Expired Login Again!',
              //           showClass: {
              //             popup: 'animate__animated animate__fadeInDown'
              //           },
              //           hideClass: {
              //             popup: 'animate__animated animate__fadeOutUp'
              //           }
              //         }).then((result)=>{
              //           if (result.isConfirmed) {
              //         console.log("11111")
              //         setdefalert(false)

              //         property("/login")
              //         localStorage.clear();
              //         // window.location.reload();
              //           }
              //       })
              //       }

              //       props.loaderchange("false");
              //     });
              // }, 3000);
            } else {
            }
          });
        }

        updated();
      } else {
        if (data.tenure == "") {
          seterrortenure("Tenure Cannot be empty!");
        }
        if (data.property_address == "") {
          seterrorpropertyaddress("Property Address Cannot be empty!");
        }
        if (data.price == "") {
          seterrorprice("Price Cannot be empty!");
        }
        if (data.agent_phone_number == "") {
          setPhoneError("Phone cannot be empty!");
        }
        if (data.bathrooms == "") {
          seterrorbathrooms("Bathrooms Fields Required!");
        }
        if (data.bedrooms == "") {
          seterrorbedrooms("Bedrooms Fields Required!");
        }
        if (data.description == "") {
          seterrordescription("Description cannot be empty!");
        }
        if (data.latitude == "") {
          seterrorlatitude("Latitude cannot be empty!");
        }
        if (data.longitude == "") {
          seterrorlongitude("Longitude cannot be empty!");
        }
        if (data.type == "") {
          setschooltype("School Type cannot be empty!");
        }
        if (data.distance == "") {
          setschooldistance("School Distance cannot be empty!");
        }

        if (keyfeature == "" || keyfeature == null) {
          setlangerror(true);
          // $(".react-tagsinput-language").css({
          //   "border-bottom": "2px solid red",
          // });
        }

        if (data.media_files.length == 0 
          //  data.blueprint.length == 0
           ) {
          setalert(true);
        }
      }
    } else {
      const div = document.getElementsByClassName("ui-state-default");
      let array = data.media_files;
      let indexlist = [];
      for (let i = 0; i < div.length; i++) {
        indexlist.push(div[i].getAttribute("id"));
      }
      let newarr = indexlist.map((index) => array[index]);
      ////console.log(newarr);
      u1 = newarr;
      setData({
        ...data,
        media_files: newarr,
      });

      const div2 = document.getElementsByClassName("ui-state-default2");
      let array2 = data.blueprint;
      let indexlist2 = [];
      for (let i = 0; i < div2.length; i++) {
        indexlist2.push(div2[i].getAttribute("id"));
      }
      let newarr2 = indexlist2.map((index) => array2[index]);
      ////console.log(newarr2);
      u2 = newarr2;
      setData({
        ...data,
        blueprint: newarr2,
      });

      const div3 = document.getElementsByClassName("ui-state-default3");
      let array3 = data.before;
      let indexlist3 = [];
      for (let i = 0; i < div3.length; i++) {
        indexlist3.push(div3[i].getAttribute("id"));
      }
      let newarr3 = indexlist3.map((index) => array3[index]);
      ////console.log(newarr3);
      u3 = newarr3;
      setData({
        ...data,
        before: newarr3,
      });

      const div4 = document.getElementsByClassName("ui-state-default4");
      let array4 = data.after;
      let indexlist4 = [];
      for (let i = 0; i < div4.length; i++) {
        indexlist4.push(div4[i].getAttribute("id"));
      }
      let newarr4 = indexlist4.map((index) => array4[index]);
      ////console.log(newarr4);
      u4 = newarr4;
      setData({
        ...data,
        after: newarr4,
      });
      craeteproperty = data;
      craeteproperty.media_files = newarr;
      craeteproperty.blueprint = newarr2;
      craeteproperty.before = newarr3;
      craeteproperty.after = newarr4;

      if (data.property_address != "" && data.description != "") {
        async function updated() {
          Swal.fire({
            title: "Are you sure?",
            text: "You want to update the data!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Update it!",
          }).then((result) => {
            ////console.log(result);

            if (result.isConfirmed) {
              props.loaderchange("true");

              // mapurl2.forEach((ele, index) => {
              //   async function uploadimage2() {
              //     const resp = await fetch(ele.url, {
              //       method: "PUT",
              //       body: ele.file,
              //       headers: {
              //         // "Authorization": "Bearer " + localStorage.getItem("token") + "",
              //         "Content-Type": ele.type,
              //         "X-Amz-ACL": "public-read",
              //       },
              //     })
              //       .then((res) => {
              //         if(resp.ok){
              //           if(index+1==mapurl2.length){

              //           }
              //         }
              //         // ////console.log(index,mapurl2.length)
              //         // //debugger
              //         // if (index + 1 === mapurl2.length) {
              //         //   ////console.log(res, "[[[[[");
              //         //   // props.loaderchange("false");
              //         // }
              //       })

              //       .catch((err) => {
              //         ////console.log(err);
              //         props.loaderchange("false");
              //         return null;
              //       });
              //   }
              //   uploadimage2();
              // });

              // mapurl.forEach((ele, index1) => {
              //   async function uploadimage() {
              //     setTimeout(async ()=>{
              //       const resp1 = await fetch(ele.url, {
              //         method: "PUT",
              //         body: ele.file,
              //         headers: {
              //           // "Authorization": "Bearer " + localStorage.getItem("token") + "",
              //           "Content-Type": ele.type,
              //           "X-Amz-ACL": "public-read",
              //         },
              //       })
              //         .then((res) => {
              //           if(resp1.ok){
              //             if(index1+1 == mapurl.length){

              //             }
              //           }
              //           ////console.log(res);
              //           // props.loaderchange("false");
              //         })

              //         .catch((err) => {
              //           ////console.log(err);
              //           props.loaderchange("false");
              //           return null;
              //         });
              //     },200)
              //   }
              //   uploadimage();
              // });
              console.log(beforemapurl);

              if (beforemapurl.length > 0) {
                beforemapurl.forEach((ele, index2) => {
                  async function uploadimage3() {
                    const resp2 = await fetch(ele.url, {
                      method: "PUT",
                      body: ele.file,
                      headers: {
                        // "Authorization": "Bearer " + localStorage.getItem("token") + "",
                        "Content-Type": ele.type,
                        "X-Amz-ACL": "public-read",
                      },
                    })
                      .then((res) => {
                        //debugger
                        if (index2 + 1 == beforemapurl.length) {
                          //debugger
                          console.log(aftermapurl);
                          if (aftermapurl.length > 0) {
                            aftermapurl.forEach((ele, index3) => {
                              async function uploadimage4() {
                                const resp3 = await fetch(ele.url, {
                                  method: "PUT",
                                  body: ele.file,
                                  headers: {
                                    // "Authorization": "Bearer " + localStorage.getItem("token") + "",
                                    "Content-Type": ele.type,
                                    "X-Amz-ACL": "public-read",
                                  },
                                })
                                  .then((res) => {
                                    //debugger

                                    if (index3 + 1 == aftermapurl.length) {
                                      //debugger
                                      axios({
                                        method: "put",
                                        data: craeteproperty,
                                        url:
                                          propertyedit +
                                          editpropertyuserid +
                                          "/",
                                        headers: {
                                          Authorization:
                                            "Bearer " +
                                            localStorage.getItem("token") +
                                            "",
                                        },
                                      })
                                        .then((res) => {
                                          // //debugger;
                                          Swal.fire(
                                            "Updated!",
                                            "Your data has been updated.",
                                            "success"
                                          );

                                          if (res.status == 200) {
                                            property("/");
                                            // setTimeout(() => {
                                            //   window.location.reload();
                                            // }, 300);
                                            props.loaderchange("false");
                                          }
                                        })
                                        .catch((error) => {
                                          ////console.log(error);
                                          if (error.response.status == "401") {
                                            Swal.fire({
                                              title:
                                                "Token Expired Login Again!",
                                              showClass: {
                                                popup:
                                                  "animate__animated animate__fadeInDown",
                                              },
                                              hideClass: {
                                                popup:
                                                  "animate__animated animate__fadeOutUp",
                                              },
                                            }).then((result) => {
                                              if (result.isConfirmed) {
                                                console.log("11111");
                                                setdefalert(false);

                                                property("/login");
                                                localStorage.clear();
                                                // window.location.reload();
                                              }
                                            });
                                          }

                                          props.loaderchange("false");
                                        });
                                    }
                                    // ////console.log(res);
                                    // props.loaderchange("false");
                                  })

                                  .catch((err) => {
                                    ////console.log(err);
                                    props.loaderchange("false");
                                    return null;
                                  });
                              }
                              uploadimage4();
                            });
                          } else {
                            axios({
                              method: "put",
                              data: craeteproperty,
                              url: propertyedit + editpropertyuserid + "/",
                              headers: {
                                Authorization:
                                  "Bearer " +
                                  localStorage.getItem("token") +
                                  "",
                              },
                            })
                              .then((res) => {
                                // //debugger;
                                Swal.fire(
                                  "Updated!",
                                  "Your data has been updated.",
                                  "success"
                                );

                                if (res.status == 200) {
                                  property("/");
                                  // setTimeout(() => {
                                  //   window.location.reload();
                                  // }, 300);
                                  props.loaderchange("false");
                                }
                              })
                              .catch((error) => {
                                ////console.log(error);
                                props.loaderchange("false");
                                if (error.response.status == "401") {
                                  Swal.fire({
                                    title: "Token Expired Login Again!",
                                    showClass: {
                                      popup:
                                        "animate__animated animate__fadeInDown",
                                    },
                                    hideClass: {
                                      popup:
                                        "animate__animated animate__fadeOutUp",
                                    },
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      console.log("11111");
                                      setdefalert(false);

                                      property("/login");
                                      localStorage.clear();
                                      // window.location.reload();
                                    }
                                  });
                                } else {
                                  errorfunction();
                                }

                                props.loaderchange("false");
                              });
                          }
                        }
                      })

                      .catch((err) => {
                        ////console.log(err);
                        props.loaderchange("false");
                        return null;
                      });
                  }
                  uploadimage3();
                });
              } else if (aftermapurl.length > 0) {
                aftermapurl.forEach((ele, index3) => {
                  async function uploadimage4() {
                    const resp3 = await fetch(ele.url, {
                      method: "PUT",
                      body: ele.file,
                      headers: {
                        // "Authorization": "Bearer " + localStorage.getItem("token") + "",
                        "Content-Type": ele.type,
                        "X-Amz-ACL": "public-read",
                      },
                    })
                      .then((res) => {
                        if (index3 + 1 == aftermapurl.length) {
                          axios({
                            method: "put",
                            data: craeteproperty,
                            url: propertyedit + editpropertyuserid + "/",
                            headers: {
                              Authorization:
                                "Bearer " + localStorage.getItem("token") + "",
                            },
                          })
                            .then((res) => {
                              // //debugger;
                              Swal.fire(
                                "Updated!",
                                "Your data has been updated.",
                                "success"
                              );

                              if (res.status == 200) {
                                property("/");
                                // setTimeout(() => {
                                //   window.location.reload();
                                // }, 300);
                                props.loaderchange("false");
                              }
                            })
                            .catch((error) => {
                              ////console.log(error);
                              props.loaderchange("false");
                              if (error.response.status == "401") {
                                Swal.fire({
                                  title: "Token Expired Login Again!",
                                  showClass: {
                                    popup:
                                      "animate__animated animate__fadeInDown",
                                  },
                                  hideClass: {
                                    popup:
                                      "animate__animated animate__fadeOutUp",
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    console.log("11111");
                                    setdefalert(false);

                                    property("/login");
                                    localStorage.clear();
                                    // window.location.reload();
                                  }
                                });
                              } else {
                                errorfunction();
                              }

                              props.loaderchange("false");
                            });
                        }
                      })

                      .catch((err) => {
                        ////console.log(err);
                        props.loaderchange("false");
                        return null;
                      });
                  }
                  uploadimage4();
                });
              } else {
                axios({
                  method: "put",
                  data: craeteproperty,
                  url: propertyedit + editpropertyuserid + "/",
                  headers: {
                    Authorization:
                      "Bearer " + localStorage.getItem("token") + "",
                  },
                })
                  .then((res) => {
                    // //debugger;
                    Swal.fire(
                      "Updated!",
                      "Your data has been updated.",
                      "success"
                    );

                    if (res.status == 200) {
                      property("/");
                      // setTimeout(() => {
                      //   window.location.reload();
                      // }, 300);
                      props.loaderchange("false");
                    }
                  })
                  .catch((error) => {
                    ////console.log(error);
                    props.loaderchange("false");
                    if (error.response.status == "401") {
                      Swal.fire({
                        title: "Token Expired Login Again!",
                        showClass: {
                          popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                          popup: "animate__animated animate__fadeOutUp",
                        },
                      }).then((result) => {
                        if (result.isConfirmed) {
                          console.log("11111");
                          setdefalert(false);

                          property("/login");
                          localStorage.clear();
                          // window.location.reload();
                        }
                      });
                    } else {
                      errorfunction();
                    }

                    props.loaderchange("false");
                  });
              }
            } else {
              const div = document.getElementsByClassName("ui-state-default");
              let array = data.media_files;
              let newarr = [];
              let indexlist = [];
              for (let i = 0; i < div.length; i++) {
                indexlist.push(div[i].getAttribute("id"));
              }
              newarr = indexlist.map((index) => array[index]);
              setData((prev) => {
                return {
                  ...prev,
                  media_files: newarr,
                };
              });
              ////console.log(div, newarr, indexlist);
            }
          });
        }
        updated();
      } else {
        if (data.property_address == "") {
          seterrorpropertyaddress("Property Address Cannot be empty!");
        }
        if (data.description == "") {
          seterrordescription("Description cannot be empty!");
        }
        ////console.log(alert, alert2, newerror);
      }
    }
  };

  const cancelnewuser = () => {
    ////console.log("1111");
    if (alertpopup) {
      Swal.fire({
        title: "Are you sure?",
        text: "There are unsaved changes, do you want to go back?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, revert it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // Swal.fire("Reverted!", "Given Info has been Removed.", "success");
          property("/");
          setalertpopup(false);
        } else {
          setalertpopup(true);
        }
      });
    } else {
      property("/");
    }
  };

  useEffect(() => {
    axios({
      method: "get",
      url: propertycreate + editpropertyuserid + "/",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token") + "",
      },
    })
      .then((res) => {
        props.loaderchange("false");
        console.log(res.data.data, "kgkhgkghkhgkghkghkghkhg");
        let arr = res.data.data;
        // arr.pop()
        delete arr.table_data_details;
        // setData(arr);
        // setData({
        //   ...data,
        //   bedrooms:String(res.data.data.bedrooms),
        //   bathrooms:String(res.data.data.bathrooms)
        // })
        setData({
          ...arr,
          bedrooms: String(res.data.data.bedrooms),
          bathrooms: String(res.data.data.bathrooms)
        });
        
        setChecked(res.data.data.is_active);
        setbathroom(String(res.data.data.bathrooms));
        setbedrooms(String(res.data.data.bedrooms));
        setFile(
          res.data.data.media_files != null ? res.data.data.media_files : ""
        );
        setFile2(
          res.data.data.blueprint != null ? res.data.data.blueprint : ""
        );
        setbeforeimg(res.data.data.before != null ? res.data.data.before : "");
        setafterimg(res.data.data.after != null ? res.data.data.after : "");
        let key = [];
        res.data.data.key_features != "" &&
          res.data.data.key_features.forEach((element) => {
            // console.log(res.data.data.titles,res.data.data.profile_pic, "id");
            key.push(element);
          });
        setkeyfeature(key);
        // settype(res.data.data.medicine_type);
        res.data.data.media_files &&
          res.data.data.media_files.length > 0 &&
          res.data.data.media_files.map((ele) => {
            u1.push(ele);
          });
        res.data.data.blueprint &&
          // res.data.data.blueprint.length > 0
          res.data.data.blueprint.map((ele) => {
            u2.push(ele);
          });

        res.data.data.before &&
          res.data.data.before.length > 0 &&
          res.data.data.before.map((ele) => {
            u3.push(ele);
          });
        res.data.data.after &&
          res.data.data.after.length > 0 &&
          res.data.data.after.map((ele) => {
            u4.push(ele);
          });
        ////console.log(u4)
        // setcreatpharmacy({
        //   ...creatpharmacy,
        //   product_name: res.data.data.product_name,
        //   MRP: res.data.data.MRP,
        //   stock: res.data.data.stock,
        //   discount: res.data.data.discount,
        //   is_active: res.data.data.is_active,
        //   logo_url: res.data.data.logo_url,
        //   medicine_type: res.data.data.medicine_type,
        // });
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == "401") {
          Swal.fire({
            title: "Token Expired Login Again!",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              property("/login");
              localStorage.clear();
              // window.location.reload();
            }
          });
        }
        ////console.log(error);
        // props.popupalert("true");
        // props.popuptext(error.response.data.status.message);
        // setTimeout(() => {
        // props.popupalert("false");
        // }, 2000);
        props.loaderchange("false");
      });
  }, []);

  const uploadSingleFile2 = (e) => {
    setalertpopup(true);
    ////console.log(Array.from(e.target.files));

    if (e.target.files.length == 1) {
      selectedImage(e);
      setCroppImageViewCount(1);
      setCroppImageView(true);
    } else if (e.target.files[0]) {
      var name;
      ////console.log(file, "b11111111");
      const filesArray = Array.from(e.target.files); // Convert FileList to array

      Object.keys(e.target.files).map((ele) => {
        setFile((oldArray) => [
          ...oldArray,
          String(URL.createObjectURL(e.target.files[ele])),
        ]);

        setimgfile2((oldArray) => [...oldArray, e.target.files[ele]]);
        setimgtype2((oldArray) => [...oldArray, e.target.files[ele].type]);
        var val = Math.floor(1000 + Math.random() * 9000);
        name = {
          multiple_files: filesArray.map((file) => ({
            ["filename"]:
              "assestsfortesting" +
              "/" +
              String(val) +
              String(file.name).replace(/ +/g, ""),
            ["file_type"]: file.type,
          })),
        };

        ////console.log(name, "111");
      });
      axios({
        method: "post",
        url: presignedurl2,
        data: name,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") + "",
        },
      })
        .then((res) => {
          setpreurl2((oldArray) => [
            ...oldArray,
            String(res.data.data).split("?")[0],
          ]);
          ////console.log(res.data.data, "222");
          res.data.data.map((ele, index) => {
            u1.push(String(ele).split("?")[0]);
            setmapurl2((oldArray) => [
              ...oldArray,
              {
                url: ele,
                type: name.multiple_files[index]["file_type"],
                file: filesArray[index],
              },
            ]);
          });
          ////console.log(mapurl2, "33333");
          setData({
            ...data,
            media_files: u1,
          });
          ////console.log(u1, "444444");
          ////console.log(data.media_files, "5555555");
        })
        .catch((error) => {
          ////console.log(error);
          console.log(error);
          if (error.response.status == "401") {
            Swal.fire({
              title: "Token Expired Login Again!",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                property("/login");
                localStorage.clear();
                // window.location.reload();
              }
            });
          }
        });
    }
  };
  const deleteFile2 = (e) => {
    setalertpopup(true);

    if (preurl2 && preurl2.length == 0) {
      setindex2(0);
    }
    const s = file.filter((item, index) => index !== e);
    const t = imgfile2.filter((item, index) => index !== e);
    const u = preurl2.filter((item, index) => index !== e);
    const v = mapurl2.filter((item, index) => index !== e);
    const w = u1.filter((item, index) => index !== e);

    setimgfile2(t);
    setpreurl2(u);
    setFile(s);
    setmapurl2(v);

    u1 = [];

    u1 = w;
    ////console.log(u1, "u1");
    setData({
      ...data,
      media_files: u1,
    });
  };

  const uploadSingleFile = (e) => {
    setalertpopup(true);

    if (e.target.files.length == 1) {
      selectedImage(e);
      setCroppImageViewCount(2);
      setCroppImageView(true);
    } else if (e.target.files[0]) {
      var name2;
      ////console.log(file);
      const filesArray = Array.from(e.target.files); // Convert FileList to array

      Object.keys(e.target.files).map((ele) => {
        setFile2((oldArray) => [
          ...oldArray,
          String(URL.createObjectURL(e.target.files[ele])),
        ]);

        setimgfile((oldArray) => [...oldArray, e.target.files[ele]]);
        setimgtype((oldArray) => [...oldArray, e.target.files[ele].type]);
        var val = Math.floor(1000 + Math.random() * 9000);
        // var name2 = {
        //   ["filename"]:
        //     "assestsfortesting" +
        //     "/" +
        //     String(val) +
        //     String(e.target.files[ele].name).replace(/ +/g, ""),
        //   ["file_type"]: e.target.files[ele].type,
        // };

        name2 = {
          multiple_files: filesArray.map((file) => ({
            ["filename"]:
              "assestsfortesting" +
              "/" +
              String(val) +
              String(file.name).replace(/ +/g, ""),
            ["file_type"]: file.type,
          })),
        };
      });
      axios({
        method: "post",
        url: presignedurl2,
        data: name2,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") + "",
        },
      })
        .then((res) => {
          setpreurl((oldArray) => [
            ...oldArray,
            String(res.data.data).split("?")[0],
          ]);

          res.data.data.map((ele, index) => {
            u2.push(String(ele).split("?")[0]);
            setmapurl((oldArray) => [
              ...oldArray,
              {
                url: ele,
                type: name2.multiple_files[index]["file_type"],
                file: filesArray[index],
              },
            ]);
          });
          setData({
            ...data,
            blueprint: u2,
          });
        })
        .catch((error) => {
          ////console.log(error);
          console.log(error);
          if (error.response.status == "401") {
            Swal.fire({
              title: "Token Expired Login Again!",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                property("/login");
                localStorage.clear();
                // window.location.reload();
              }
            });
          }
        });
    }
  };

  const deleteFile = (e) => {
    setalertpopup(true);

    if (preurl && preurl.length == 0) {
      setindex(0);
    }
    const a = file2.filter((item, index) => index !== e);
    const b = imgfile.filter((item, index) => index !== e);
    const c = preurl.filter((item, index) => index !== e);
    const d = mapurl.filter((item, index) => index !== e);
    const f = u2.filter((item, index) => index !== e);

    setimgfile(b);
    setpreurl(c);
    setFile2(a);
    setmapurl(d);
    ////console.log(f, "lll");
    u2 = [];

    u2 = f;
    ////console.log(u2);
    setData({
      ...data,
      blueprint: u2,
    });
  };
  const dropchange = (e) => {
    setData({
      ...data,
      d_property_status: e.target.value,
    });
  };
  const dropdownforbanners = (e) => {
    setalertpopup(true);

    var banner = e.target.value;
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    ////console.log(data);
    if (validator.isEmpty(banner.trim())) {
      setbannererror("bannerdropdown Cannot be empty!");
    } else {
      setbannererror("");
    }
  };

  // const dropdownPropertytype = (e) => {
  //   var banner = e.target.value;
  //   const { name, value } = e.target;
  //   setData({
  //     ...data,
  //     [name]: value,
  //   });
  //   ////console.log(data)
  //   if (validator.isEmpty(banner.trim())) {
  //     setPropertyTypeError("Property Type Cannot be empty!");
  //   } else {
  //     setPropertyTypeError("");
  //   }
  // };
  const dropdownPropertytype = (e) => {
    setalertpopup(true);

    if (e != null) {
      ////console.log(e.value);
      var banner = e.value;
      setData({
        ...data,
        property_type: e.value,
      });
      ////console.log(data);
      if (validator.isEmpty(banner.trim())) {
        setPropertyTypeError("Property Type Cannot be empty!");
      } else {
        setPropertyTypeError("");
      }
    } else {
      setData({
        ...data,
        property_type: "",
      });
      // ////console.log(data)
      // if (validator.isEmpty(banner.trim())) {
      //   setPropertyTypeError("Property Type Cannot be empty!");
      // } else {
      //   setPropertyTypeError("");
      // }
    }
  };
  ///before
  const uploadSingleFilebefore = (e) => {
    setalertpopup(true);

    if (e.target.files.length == 1) {
      selectedImage(e);
      setCroppImageViewCount(3);
      setCroppImageView(true);
    } else if (e.target.files[0]) {
      var name3;
      ////console.log(file, "11111111");
      const filesArray3 = Array.from(e.target.files); // Convert FileList to array

      Object.keys(e.target.files).map((ele) => {
        setbeforeimg((oldArray) => [
          ...oldArray,
          String(URL.createObjectURL(e.target.files[ele])),
        ]);

        setbeforeimgfile((oldArray) => [...oldArray, e.target.files[ele]]);
        setbeforeimgtype((oldArray) => [...oldArray, e.target.files[ele].type]);

        var val = Math.floor(1000 + Math.random() * 9000);
        name3 = {
          multiple_files: filesArray3.map((file) => ({
            ["filename"]:
              "assestsfortesting" +
              "/" +
              String(val) +
              String(file.name).replace(/ +/g, ""),
            ["file_type"]: file.type,
          })),
        };
      });

      axios({
        method: "post",
        url: presignedurl2,
        data: name3,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") + "",
        },
      })
        .then((res) => {
          setbeforepreurl((oldArray) => [
            ...oldArray,
            String(res.data.data).split("?")[0],
          ]);
          res.data.data.map((ele, index) => {
            u3.push(String(ele).split("?")[0]);
            setbeforemapurl((oldArray) => [
              ...oldArray,
              {
                url: ele,
                type: name3.multiple_files[index]["file_type"],
                file: filesArray3[index],
              },
            ]);
          });
          setData({
            ...data,
            before: u3,
          });
        })
        .catch((error) => {
          ////console.log(error);
          console.log(error);
          if (error.response.status == "401") {
            Swal.fire({
              title: "Token Expired Login Again!",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                property("/login");
                localStorage.clear();
                // window.location.reload();
              }
            });
          }
        });
    }
  };

  const deleteFilebefore = (e) => {
    setalertpopup(true);

    if (preurl && preurl.length == 0) {
      setindex(0);
    }
    const s = beforeimg.filter((item, index) => index !== e);
    const t = beforeimgfile.filter((item, index) => index !== e);
    const u = beforepreurl.filter((item, index) => index !== e);
    const v = beforepreurl.filter((item, index) => index !== e);
    const w = u3.filter((item, index) => index !== e);

    setbeforeimgfile(t);
    setbeforepreurl(u);
    setbeforeimg(s);
    setbeforemapurl(v);
    ////console.log(w);
    u3 = [];

    u3 = w;
    ////console.log(u3);
    setData({
      ...data,
      before: u3,
    });
  };
  const uploadSingleFileafter = (e) => {
    setalertpopup(true);

    if (e.target.files.length == 1) {
      selectedImage(e);
      setCroppImageViewCount(4);
      setCroppImageView(true);
    } else if (e.target.files[0]) {
      var name4;
      ////console.log(file, "11111111");
      const filesArray4 = Array.from(e.target.files); // Convert FileList to array

      Object.keys(e.target.files).map((ele) => {
        setafterimg((oldArray) => [
          ...oldArray,
          String(URL.createObjectURL(e.target.files[ele])),
        ]);
        setafterimgfile((oldArray) => [...oldArray, e.target.files[ele]]);
        setafterimgtype((oldArray) => [...oldArray, e.target.files[ele].type]);
        var val = Math.floor(1000 + Math.random() * 9000);
        // name4 = {
        //   ["filename"]:
        //     "assestsfortesting" +
        //     "/" +
        //     String(val) +
        //     String(e.target.files[ele].name).replace(/ +/g, ""),
        //   ["file_type"]: e.target.files[ele].type,
        // };
        name4 = {
          multiple_files: filesArray4.map((file) => ({
            ["filename"]:
              "assestsfortesting" +
              "/" +
              String(val) +
              String(file.name).replace(/ +/g, ""),
            ["file_type"]: file.type,
          })),
        };
      });
      axios({
        method: "post",
        url: presignedurl2,
        data: name4,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") + "",
        },
      })
        .then((res) => {
          setafterpreurl((oldArray) => [
            ...oldArray,
            String(res.data.data).split("?")[0],
          ]);
          // u4.push(String(res.data.data).split("?")[0]);
          res.data.data.map((ele, index) => {
            u4.push(String(ele).split("?")[0]);
            setaftermapurl((oldArray) => [
              ...oldArray,
              {
                url: ele,
                type: name4.multiple_files[index]["file_type"],
                file: filesArray4[index],
              },
            ]);
          });

          setData({
            ...data,
            after: u4,
          });
        })
        .catch((error) => {
          ////console.log(error);
          console.log(error);
          if (error.response.status == "401") {
            Swal.fire({
              title: "Token Expired Login Again!",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                property("/login");
                localStorage.clear();
                // window.location.reload();
              }
            });
          }
        });
    }
  };

  const deleteFileafter = (e) => {
    ////console.log(e,u4)
    setalertpopup(true);

    if (preurl && preurl.length == 0) {
      setindex(0);
    }
    const s = afterimg.filter((item, index) => index !== e);
    const t = afterimgfile.filter((item, index) => index !== e);
    const u = afterpreurl.filter((item, index) => index !== e);
    const v = afterpreurl.filter((item, index) => index !== e);
    const w = u4.filter((item, index) => index !== e);

    setafterimgfile(t);
    setafterpreurl(u);
    setafterimg(s);
    setaftermapurl(v);
    ////console.log(w);
    u4 = [];

    u4 = w;
    ////console.log(u4);
    setData({
      ...data,
      after: u4,
    });
  };

  ///COMPLETED PROJECT
  const completedvalidation = (e) => {
    setalertpopup(true);

    const { name, value } = e.target;
    var input = e.target.value;
    var numericInput = input.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    ////console.log(numericInput);
    e.target.value = numericInput;
    ////console.log(numericInput, e.target.value);
    let val = new Intl.NumberFormat("en-GB").format(numericInput).toString();
    ////console.log(val);
    setData({
      ...data,
      [name]: val,
    });
    // setData({
    //   ...data,
    //   [name]: value,
    // });
  };

  $(function () {
    $("#sortable").sortable({});
    $("#sortable2").sortable({});
    $("#sortable3").sortable({});
    $("#sortable4").sortable({});
  });

  const showAlert = (e) => {
    e.returnValue = "";
  };

  useEffect(() => {
    if (defalert) {
      window.addEventListener("beforeunload", showAlert);
      return () => {
        window.removeEventListener("beforeunload", showAlert);
      };
    }
  });

  return (
    <div
      // className={sideactive ? "propertyform-sa" : "propertyform"}
      className="propertyform"
    >
      {CroppImageView && (
        <div className="Cropper_Wrapper">
          <div className="Cropper_Inner_Wrapper">
            <ReactCrop
              src={imgsrc}
              crop={crop}
              ruleOfThirds
              onImageLoaded={onImageLoaded}
              onChange={(c) => {
                setCrop(c);
                ////console.log(c);
              }}
              // onComplete={(c) => setCompletedCrop(c)}
            >
              <img src={imgsrc} ref={imgRef} id="localimg" />
            </ReactCrop>
          </div>
          <div className="CropperFooter">
            <button onClick={(e) => setCroppImageView(false)}>Cancel</button>
            <button onClick={(e) => setCompletedCrop(crop)}>Save</button>
          </div>
        </div>
      )}
      {Object.keys(data).length > 0 && (
        <div class="card" style={{ margin: "30px" }}>
          <div class="card-header">
            <h5
              class="card-title"
              style={{ padding: "6px", margin: 0, fontWeight: "800" }}
            >
              {data.property_address}
            </h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm">
                <form class="row g-3 needs-validation">
                  <div class="col-md-4">
                    {/* <label for="validationCustom01" class="form-label">
                      Tenure
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="validationCustom01"
                      name="tenure"
                      autoComplete="off"
                      placeholder="tenure"
                      value={data.tenure}
                      onChange={validateTenure}
                      required
                    ></input> */}
                    <label for="validationCustom01" class="form-label">
                      Property Status
                    </label>

                    <select
                      className="form-control1"
                      placeholder="Please Select"
                      name="tenure"
                      value={data.tenure}
                      onChange={validateTenure}
                    >
                      <option value={""} disabled selected>
                        Please Select
                      </option>
                      <option value={"Freehold"}>Freehold</option>
                      <option value={"Leasehold"}>Leasehold</option>
                      <option value={"Ask the Agent"}> Ask the Agent </option>
                    </select>

                    <p
                      className={`FormErrors ${
                        errortenure != "" ? "show" : "hide"
                      }`}
                    >
                      {errortenure}
                    </p>
                  </div>

                  <div class="col-md-4">
                    <label for="validationCustom03" class="form-label">
                      Price
                    </label>
                    <span className="pound">£</span>
                    <input
                      type="text"
                      class="form-control"
                      id="validationCustom03"
                      placeholder="Price"
                      autoComplete="off"
                      name="price"
                      // pattern="[0-9,]*"
                      onChange={validatePrice}
                      value={data.price}
                      required
                    ></input>
                    <p
                      className={`FormErrors ${
                        errorprice != "" ? "show" : "hide"
                      }`}
                    >
                      {errorprice}
                    </p>
                  </div>

                  {/* <div class="col-md-4">
                    <label for="validationCustom04" class="form-label">
                      Agent Phone Number
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="validationCustom04"
                      autoComplete="off"
                      placeholder="Agent Phone Number"
                      name="agent_phone_number"
                      onChange={validatePhone}
                      value={data.agent_phone_number}
                      required
                    ></input>
                                      <p
                    className={`FormErrors ${
                      phoneError != "" ? "show" : "hide"
                    }`}
                  >
                    {phoneError}
                  </p>

                  </div> */}

                  <div class="col-md-4">
                    <label for="validationCustom05" class="form-label">
                      Bathrooms
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="validationCustom05"
                      placeholder="Bathroom"
                      autoComplete="off"
                      name="bathrooms"
                      onChange={validateBathrooms}
                      value={bathroom}
                      required
                    ></input>
                    <p
                      className={`FormErrors ${
                        errorbathrooms != "" ? "show" : "hide"
                      }`}
                    >
                      {errorbathrooms}
                    </p>
                  </div>

                  <div class="col-md-4">
                    <label for="validationCustom06" class="form-label">
                      Bedrooms
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="validationCustom06"
                      placeholder="Bedroom"
                      autoComplete="off"
                      name="bedrooms"
                      onChange={validateBedrooms}
                      value={bedrooms}
                      required
                    ></input>
                    <p
                      className={`FormErrors ${
                        errorbedrooms != "" ? "show" : "hide"
                      }`}
                    >
                      {errorbedrooms}
                    </p>
                  </div>

                  <div class="col-md-4">
                    <label for="validationCustom07" class="form-label">
                      Key Features
                    </label>
                    <TagsInput
                      value={keyfeature}
                      onChange={(e) => keyfeaturefield(e)}
                      // validationRegex={/^[A-Za-z]+$/}
                      addOnBlur={true}
                      // addOnPaste={true}
                      className="react-tagsinput-language"
                      name="languages_known"
                      inputProps={{
                        // placeholder: "Languages known*",
                        placeholder: "Key Features",
                      }}
                    />
                  </div>

                  {/* <div class="col-md-4">
                    <label for="validationCustom08" class="form-label">
                      Latitude
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="validationCustom08"
                      autoComplete="off"
                      placeholder="Latitude"
                      name="latitude"
                      onChange={validateLatitude}
                      value={data.latitude}
                      required
                    ></input>
                  </div>

                  <div class="col-md-4">
                    <label for="validationCustom09" class="form-label">
                      Longitude
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="validationCustom09"
                      autoComplete="off"
                      name="longitude"
                      placeholder="Longitude"
                      onChange={validateLongitude}
                      value={data.longitude}
                      required
                    ></input>
                  </div> */}

                  <div className="col-md-4">
                    <label for="validationCustom09" class="form-label">
                      Property Status
                    </label>

                    <select
                      className="form-control1"
                      placeholder="Please Select"
                      value={data.d_property_status}
                      onChange={(e) => {
                        dropchange(e);
                      }}
                    >
                      <option value={""} disabled selected>
                        Please Select
                      </option>
                      <option value={"0"}>Completed</option>
                      <option value={"1"}>Properties For Development</option>
                      <option value={"2"}>Fully Refurbed Properties</option>
                      <option value={"3"}>
                        Properties Requiring Refurbishment
                      </option>

                      {/* <option value={"false"}>Available</option> */}
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label for="validationCustom02" class="form-label">
                      Property Address
                    </label>
                    <textarea
                      type="text"
                      class="form-control1"
                      id="validationCustom02"
                      name="property_address"
                      placeholder="Property Address"
                      autoComplete="off"
                      rows={3}
                      value={data.property_address}
                      onChange={validatePropAddress}
                      required
                    ></textarea>
                    <p
                      className={`FormErrors ${
                        errorpropertyaddress != "" ? "show" : "hide"
                      }`}
                    >
                      {errorpropertyaddress}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label for="validationCustom07" class="form-label">
                      Description
                    </label>
                    {/* <textarea
                      type="text"
                      class="form-control1"
                      id="validationCustom07"
                      placeholder="Description"
                      name="description"
                      rows="3"
                      autoComplete="off"
                      value={data.description}
                      onChange={validateDescription}
                      required
                    ></textarea> */}
                    <CKEditor
                      content={data.description}
                      name="description"
                      events={{
                        change: validateDescription,
                      }}
                    />

                    <p
                      className={`FormErrors ${
                        errordescription != "" ? "show" : "hide"
                      }`}
                    >
                      {errordescription}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label for="validationCustom01" class="form-label">
                      Dropdown for Banners
                    </label>

                    <select
                      className="form-control1"
                      placeholder="Please Select"
                      name="dropdown_for_banners"
                      value={data.dropdown_for_banners}
                      onChange={(e) => {
                        dropdownforbanners(e);
                      }}
                    >
                      <option value={""} selected>
                        Please Select
                      </option>
                      <option value={"Guide Price"}>Guide Price</option>
                      <option value={"Under Offer"}>Under Offer</option>
                      <option value={"Offer in excess of"}>
                        {" "}
                        Offer in excess of{" "}
                      </option>
                      <option value={"Offer in region of"}>
                        Offer in region of
                      </option>
                    </select>
                    {/* <p
                      className={`FormErrors ${
                        bannererror != "" ? "show" : "hide"
                      }`}
                    >
                      {bannererror}
                    </p> */}
                  </div>
                  {/* <div class="col-md-4">
                    <label for="validationCustom07" class="form-label">
                      Offer Display
                    </label>
                    <textarea
                      type="text"
                      class="form-control1"
                      id="validationCustom07"
                      placeholder="offer display"
                      row="3"
                      name="offer_display"
                      autoComplete="off"
                      value={data.offer_display}
                      onChange={validateofferdisplay}
                      required
                    ></textarea> */}
                  {/* <p
                    className={`FormErrors ${
                      errordescription != "" ? "show" : "hide"
                    }`}
                  >
                    {errordescription}
                  </p> */}
                  {/* </div> */}
                  <div class="col-md-4">
                    <label for="validationCustom01" class="form-label">
                      Property Type
                    </label>

                    {/* <select
                      className="form-control1"
                      placeholder="Please Select"
                      name="property_type"
                      value={data.property_type}
                      onChange={(e) => { dropdownPropertytype(e) }}
                    >
                      <option value={""} disabled selected>Please Select</option>
                      <option value={"Mid-Terraced"}>Mid-Terraced</option>
                      <option value={"End Terraced"}>End Terraced</option>
                      <option value={"Terrace"}>Terrace</option>
                      <option value={"Semi-Detached"}>Semi-Detached</option>
                      <option value={"Flat"}>Flat</option>
                      <option value={"Apartment"}>Apartment</option>
                      <option value={"Maisonette"}>Maisonette</option>
                      <option value={"Detached"}>Detached</option>
                      <option value={"Bungalow"}>Bungalow</option>
                    </select> */}
                    <CreatableSelect
                      isClearable
                      placeholder="Please Select"
                      name="property_type"
                      value={{
                        value: data.property_type,
                        label: data.property_type,
                      }}
                      onChange={(e) => {
                        dropdownPropertytype(e);
                      }}
                      options={colourOptions}
                    />

                    <p
                      className={`FormErrors ${
                        PropertyTypeError != "" ? "show" : "hide"
                      }`}
                    >
                      {PropertyTypeError}
                    </p>
                  </div>

                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-7">
                        <h5>Nearest Schools</h5>
                        {data.nearest_schools.map((item, index) => (
                          <div
                            className="col-md-6 nearestschool none"
                            key={index}
                          >
                            <p className="type">Name</p>
                            <input
                              style={{
                                width: "140px",
                              }}
                              type="text"
                              className="form-control"
                              id={`validationCustom10-${index}`}
                              autoComplete="off"
                              placeholder="school name"
                              name="schoolname"
                              onChange={(e) => validateLongitude1(e, index)}
                              value={item.schoolname}
                              required
                            />

                            <p className="type">Type</p>
                            <input
                              style={{
                                width: "100px",
                              }}
                              type="text"
                              className="form-control"
                              id={`validationCustom10-${index}`}
                              autoComplete="off"
                              name="type"
                              placeholder="Type"
                              onChange={(e) => validateLongitude1(e, index)}
                              value={item.type}
                              required
                            />
                            <span
                              className={`FormErrors ${
                                schooltype != "" ? "show" : "hide"
                              }`}
                            >
                              {schooltype}
                            </span>

                            <p className="distance">Distance</p>
                            <input
                              style={{
                                width: "100px",
                              }}
                              type="number"
                              className="form-control"
                              id={`validationCustom11-${index}`}
                              autoComplete="off"
                              name="school"
                              placeholder="Distance"
                              onChange={(e) => validateLongitude1(e, index)}
                              value={item.school}
                              required
                            />
                            <span
                              className={`FormErrors ${
                                schooldistance != "" ? "show" : "hide"
                              }`}
                            >
                              {schooldistance}
                            </span>
                            <p className="ofsted">Ofsted</p>
                            <input
                              style={{
                                width: "100px",
                              }}
                              type="text"
                              className="form-control"
                              id={`validationCustom12-${index}`}
                              autoComplete="off"
                              name="Ofsted"
                              placeholder="Ofsted"
                              onChange={(e) => validateLongitude1(e, index)}
                              value={item.Ofsted}
                              required
                            />
                            {index == 0 && (
                              <div
                                className="btn"
                                onClick={() => addItemToList()}
                              >
                                +
                              </div>
                            )}
                            {index != 0 && (
                              <div
                                className="btn"
                                onClick={() => removeItemFromList(index)}
                              >
                                -
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="col-md-6">
                        <h5>Nearest Station</h5>
                        {data.nearest_stations.map((item, index) => (
                          <div
                            className="col-md-12 nearestschool none"
                            key={index}
                          >
                            <p className="ofsted">Name</p>
                            <input
                              style={{
                                width: "100px",
                              }}
                              type="text"
                              className="form-control"
                              id={`validationCustom14-${index}`}
                              autoComplete="off"
                              name="station_name"
                              placeholder="Name"
                              onChange={(e) => validateLongitude(e, index)}
                              value={item.station_name}
                              required
                            />
                            <p className="distance">Distance</p>
                            <input
                              style={{
                                width: "100px",
                              }}
                              type="number"
                              className="form-control"
                              id={`validationCustom13-${index}`}
                              autoComplete="off"
                              name="station"
                              placeholder="Distance"
                              onChange={(e) => validateLongitude(e, index)}
                              value={item.station}
                              required
                            />

                            {index == 0 && (
                              <div
                                className="btn"
                                onClick={() => addItemToList2()}
                              >
                                +
                              </div>
                            )}
                            {index != 0 && (
                              <div
                                className="btn"
                                onClick={() => removeItemFromList2(index)}
                              >
                                -
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <h4>Table Data Details</h4>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-md-6">
                        <h5>Purchase Price</h5>
                        {data.purchase_price != "" &&
                          data.purchase_price.map((item, index) => (
                            <div
                              className="col-md-12 nearestschool"
                              key={index}
                            >
                              <p className="distance">Loan</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                className="form-control"
                                id={`validationCustom26-${index}`}
                                autoComplete="off"
                                name="Loan"
                                placeholder="Loan"
                                onChange={(e) => {
                                  validateLongitude6(e, index);
                                }}
                                value={item.Loan}
                                required
                              />
                              <p className="ofsted">Investment</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                className="form-control"
                                id={`validationCustom28-${index}`}
                                autoComplete="off"
                                placeholder="Investment"
                                name="your_investment"
                                onChange={(e) => {
                                  validateLongitude6(e, index);
                                }}
                                value={item.your_investment}
                                required
                              />
                              <p className="ofsted">Total</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                className="form-control"
                                id={`validationCustom27-${index}`}
                                autoComplete="off"
                                name="Total"
                                placeholder="Total"
                                onChange={(e) => {
                                  validateLongitude6(e, index);
                                }}
                                value={item.Total}
                                required
                              />
                              {/* {index == 0 && (
                                  <div
                                    className="btn"
                                    onClick={() => addItemToList7()}
                                  >
                                    +
                                  </div>
                                )}
                                {index != 0 && (
                                  <div
                                    className="btn"
                                    onClick={() => removeItemFromList7(index)}
                                  >
                                    -
                                  </div>
                                )} */}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-md-6">
                        <h5>Development Cost</h5>
                        {data.development_cost &&
                          data.development_cost.length > 0 &&
                          data.development_cost.map((item, index) => (
                            <div
                              className="col-md-12 nearestschool"
                              key={index}
                            >
                              <p className="distance">Loan</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                className="form-control"
                                id={`validationCustom14-${index}`}
                                autoComplete="off"
                                name="Loan"
                                onChange={(e) => {
                                  validateLongitude2(e, index);
                                }}
                                value={item.Loan}
                                placeholder="Loan"
                                required
                              />
                              <p className="ofsted">Investment</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                className="form-control"
                                id={`validationCustom16-${index}`}
                                autoComplete="off"
                                name="your_investment"
                                placeholder="Investment"
                                onChange={(e) => {
                                  validateLongitude2(e, index);
                                }}
                                value={item.your_investment}
                                required
                              />
                              <p className="ofsted">Total</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                className="form-control"
                                id={`validationCustom15-${index}`}
                                autoComplete="off"
                                name="Total"
                                placeholder="Total"
                                onChange={(e) => {
                                  validateLongitude2(e, index);
                                }}
                                value={item.Total}
                                required
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-md-6">
                        <h5>Empty Column</h5>
                        {data.empty_column.length > 0 &&
                          data.empty_column.map((item, index) => (
                            <div
                              className="col-md-12 nearestschool"
                              key={index}
                            >
                              <p className="distance">Loan</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                className="form-control"
                                id={`validationCustom17-${index}`}
                                autoComplete="off"
                                name="Loan"
                                placeholder="Loan"
                                onChange={(e) => validateLongitude5(e, index)}
                                value={item.Loan}
                                required
                              />
                              <p className="ofsted">Investment</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                className="form-control"
                                id={`validationCustom19-${index}`}
                                autoComplete="off"
                                placeholder="Investment"
                                name="your_investment"
                                onChange={(e) => validateLongitude5(e, index)}
                                value={item.your_investment}
                                required
                              />
                              <p className="ofsted">Total</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                className="form-control"
                                id={`validationCustom18-${index}`}
                                autoComplete="off"
                                name="Total"
                                placeholder="Total"
                                onChange={(e) => validateLongitude5(e, index)}
                                value={item.Total}
                                required
                              />
                              {/* {index == 0 && (
                                  <div
                                    className="btn"
                                    onClick={() => addItemToList4()}
                                  >
                                    +
                                  </div>
                                )}
                                {index != 0 && (
                                  <div
                                    className="btn"
                                    onClick={() => removeItemFromList4(index)}
                                  >
                                    -
                                  </div>
                                )} */}
                            </div>
                          ))}
                      </div>
                      <div className="col-md-7">
                        <h5>Property Value</h5>
                        {data.property_value.length > 0 &&
                          data.property_value.map((item, index) => (
                            <div
                              className="col-md-12 nearestschool"
                              key={index}
                            >
                              <p className="distance">Loan</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                className="form-control"
                                id={`validationCustom23-${index}`}
                                autoComplete="off"
                                name="Loan"
                                placeholder="Loan"
                                onChange={(e) => {
                                  validateLongitude3(e, index);
                                }}
                                value={item.Loan}
                                required
                              />
                              <p className="ofsted">Investment</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                className="form-control"
                                id={`validationCustom25-${index}`}
                                autoComplete="off"
                                name="your_investment"
                                onChange={(e) => {
                                  validateLongitude3(e, index);
                                }}
                                value={item.your_investment}
                                placeholder="Investment"
                                required
                              />
                              <p className="ofsted">Total</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                className="form-control"
                                id={`validationCustom24-${index}`}
                                autoComplete="off"
                                name="Total"
                                onChange={(e) => {
                                  validateLongitude3(e, index);
                                }}
                                value={item.Total}
                                placeholder="Total"
                                required
                              />
                              {/* {index == 0 && (
                                  <div
                                    className="btn"
                                    onClick={() => addItemToList6()}
                                  >
                                    +
                                  </div>
                                )}
                                {index != 0 && (
                                  <div
                                    className="btn"
                                    onClick={() => removeItemFromList6(index)}
                                  >
                                    -
                                  </div>
                                )} */}
                            </div>
                          ))}
                      </div>
                      <div className="col-md-6">
                        <h5>Profit</h5>
                        {data.profit != "" &&
                          data.profit.length > 0 &&
                          data.profit.map((item, index) => (
                            <div
                              className="col-md-12 nearestschool"
                              key={index}
                            >
                              <p className="distance">Loan</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                placeholder="Loan"
                                className="form-control"
                                id={`validationCustom20-${index}`}
                                autoComplete="off"
                                name="Loan"
                                onChange={(e) => {
                                  validateLongitude4(e, index);
                                }}
                                value={item.Loan}
                                required
                              />
                              <p className="ofsted">Investment</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                className="form-control"
                                id={`validationCustom22-${index}`}
                                autoComplete="off"
                                placeholder="Investment"
                                name="your_investment"
                                onChange={(e) => {
                                  validateLongitude4(e, index);
                                }}
                                value={item.your_investment}
                                required
                              />
                              <p className="ofsted">Total</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                className="form-control"
                                id={`validationCustom21-${index}`}
                                autoComplete="off"
                                name="Total"
                                placeholder="Total"
                                onChange={(e) => {
                                  validateLongitude4(e, index);
                                }}
                                value={item.Total}
                                required
                              />
                              {/* {index == 0 && (
                                <div
                                  className="btn"
                                  onClick={() => addItemToList5()}
                                >
                                  +
                                </div>
                              )}
                              {index != 0 && (
                                <div
                                  className="btn"
                                  onClick={() => removeItemFromList5(index)}
                                >
                                  -
                                </div>
                              )} */}
                            </div>
                          ))}
                      </div>

                      <div className="col-md-12">
                        <h5>Return</h5>
                        {data.property_return != "" &&
                          // data.property_return != null &&
                          data?.property_return?.length > 0 &&
                          data?.property_return?.map((item, index) => (
                            <div
                              className="col-md-12 nearestschool"
                              key={index}
                            >
                              <p className="distance">Loan</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                placeholder="Loan"
                                className="form-control"
                                id={`validationCustom20-${index}`}
                                autoComplete="off"
                                name="Loan"
                                onChange={(e) => {
                                  validatePropertyReturn(e, index);
                                }}
                                value={item.Loan}
                                required
                              />
                              <p className="ofsted">Investment</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                className="form-control"
                                id={`validationCustom22-${index}`}
                                autoComplete="off"
                                placeholder="Investment"
                                name="your_investment"
                                onChange={(e) => {
                                  validatePropertyReturn(e, index);
                                }}
                                value={item.your_investment}
                                required
                              />
                              <p className="ofsted">Total</p>
                              <span className="pound">£</span>
                              <input
                                style={{
                                  width: "100px",
                                }}
                                type="text"
                                className="form-control"
                                id={`validationCustom21-${index}`}
                                autoComplete="off"
                                name="Total"
                                placeholder="Total"
                                onChange={(e) => {
                                  validatePropertyReturn(e, index);
                                }}
                                value={item.Total}
                                required
                              />
                              {/* {index == 0 && (
                                <div
                                  className="btn"
                                  onClick={() => addItemToList5()}
                                >
                                  +
                                </div>
                              )}
                              {index != 0 && (
                                <div
                                  className="btn"
                                  onClick={() => removeItemFromList5(index)}
                                >
                                  -
                                </div>
                              )} */}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  {/* <ul >
                    <li>1.1</li>
                    <li>2.2</li>
                    <li>3.3</li>
                    <li>4.4</li>
                    <li>5.5</li>
                   </ul> */}
                  <div className="col-md-12">
                    <div className="row">
                      <div class="col-md-6">
                        <div className="form-group preview" id="preview">
                          <ul
                            className="imgouters"
                            id="sortable"
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              // display:"inline",
                              width: "500px",
                              // height:"50/0px",
                              gap: "10px",
                              listStyleType: "none",
                              textDecoration: "none",
                            }}
                          >
                            {file &&
                              file.length > 0 &&
                              file.map((item, index) => {
                                return (
                                  <li
                                    id={index}
                                    className="ui-state-default"
                                    style={{
                                      margin: "10px 10px 10px 10px",
                                      //border: "1px solid #ddd",
                                      display: "flex",
                                    }}
                                  >
                                    <img
                                      style={{
                                        height: "3.5rem",
                                        width: "3.5rem",
                                        borderRadius: "50%",
                                        // margin: "5px 5px 5px 10px",
                                      }}
                                      src={item}
                                      alt=""
                                    />
                                    <button
                                      type="button"
                                      style={{
                                        position: "relative",
                                        height: "20px",
                                        width: "20px",
                                        margin: "-10px",
                                        borderRadius: "50%",
                                        backgroundColor: "#00D0F1",
                                        border: "none",
                                      }}
                                      onClick={() => deleteFile2(index)}
                                    >
                                      <i
                                        class="bi bi-x"
                                        style={{
                                          margin: "inherit",
                                          color: "white",
                                          position: "relative",
                                          bottom: "2px",
                                        }}
                                      ></i>
                                    </button>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>

                        <div className="form-group"></div>
                        <Button
                          className="pharmacyupload"
                          variant="contained"
                          component="label"
                          // disabled={file && file.length > 5}
                          disabled={
                            data.d_property_status == "0"
                              ? true
                              : false
                          }
                          style={{
                            cursor:
                            data.d_property_status == "0"
                                ? "not-allowed"
                                : "pointer",
                            pointerEvents: "all",
                          }}
                        >
                          {" "}
                          <input
                            type="file"
                            // disabled={file && file.length > 5}
                            hidden
                            className="form-control"
                            accept="image/png, image/jpeg"
                            onChange={uploadSingleFile2}
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                            disabled={
                              data.d_property_status == "0"
                                ? true
                                : false
                            }
                            multiple
                          />
                          Upload Image
                        </Button>
                        {alert && data.media_files.length == 0 && (
                          <p style={{ color: "red" }}>Please Upload Image</p>
                        )}
                      </div>

                      <div class="col-md-6">
                        <div className="form-group preview">
                          <ul
                            className="imgouters"
                            id="sortable2"
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              // display:"inline",
                              width: "500px",
                              // height:"50/0px",
                              gap: "10px",
                              listStyleType: "none",
                              textDecoration: "none",
                            }}
                          >
                            {file2 &&
                              file2.length > 0 &&
                              file2.map((item, index) => {
                                return (
                                  <li
                                    id={index}
                                    className="ui-state-default2"
                                    style={{
                                      margin: "10px 10px 10px 10px",
                                      //border: "1px solid #ddd",
                                      display: "flex",
                                    }}
                                  >
                                    <img
                                      style={{
                                        height: "3.5rem",
                                        width: "3.5rem",
                                        borderRadius: "50%",
                                        // margin: "10px 10px 10px 20px",
                                      }}
                                      src={item}
                                      alt=""
                                    />
                                    <button
                                      type="button"
                                      style={{
                                        position: "relative",
                                        height: "20px",
                                        width: "20px",
                                        margin: "-10px",
                                        borderRadius: "50%",
                                        backgroundColor: "#00D0F1",
                                        border: "none",
                                      }}
                                      onClick={() => deleteFile(index)}
                                    >
                                      <i
                                        class="bi bi-x"
                                        style={{
                                          margin: "inherit",
                                          color: "white",
                                          position: "relative",
                                          bottom: "2px",
                                        }}
                                      ></i>
                                    </button>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>

                        <div className="form-group"></div>
                        <Button
                          className="pharmacyupload"
                          variant="contained"
                          component="label"
                          // disabled={file2 && file2.length > 5}
                          disabled={
                            data.d_property_status == "0"
                              ? true
                              : false
                          }
                          style={{
                            cursor:
                              data.d_property_status == "0"
                                ? "not-allowed"
                                : "pointer",
                            pointerEvents: "all",
                          }}
                        >
                          {" "}
                          <input
                            type="file"
                            // disabled={file2 && file2.length > 5}
                            hidden
                            className="form-control"
                            accept="image/png, image/jpeg"
                            onChange={uploadSingleFile}
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                            disabled={
                              data.d_property_status == "0"
                                ? true
                                : false
                            }
                            multiple
                          />
                          Upload Blue Print
                        </Button>
                        {/* {alert && data.blueprint.length == 0 && (
                          <p style={{ color: "red" }}>Please Upload Image</p>
                        )} */}
                      </div>
                    </div>
                  </div>
                  {/* <></> */}
                  {data.d_property_status == "0" && (
                    <div className="completed_project">
                      <h1>Completed Project</h1>
                      <div className="row">
                        <div className="col-md-3">
                          <label class="form-label">
                            Existing Property Price
                          </label>
                          <span
                            className="pound"
                            style={{ marginLeft: "-175px" }}
                          >
                            £
                          </span>
                          <input
                            type="text"
                            name="existing_property_price"
                            placeholder="Existing Property Price"
                            class="form-control"
                            value={data.existing_property_price}
                            onChange={completedvalidation}
                            style={{ paddingLeft: "45px" }}
                          ></input>
                          {newerror && data.existing_property_price == null && (
                            <p style={{ color: "red" }}>
                              {" "}
                              Existing Property Price Required
                            </p>
                          )}
                        </div>
                        <div className="col-md-3">
                          <label class="form-label">Project Cost</label>
                          <span
                            className="pound"
                            style={{ marginLeft: "-93px" }}
                          >
                            £
                          </span>
                          <input
                            type="text"
                            name="project_cost"
                            placeholder="Project Cost"
                            class="form-control"
                            value={data.project_cost}
                            onChange={completedvalidation}
                            style={{ paddingLeft: "45px" }}
                          ></input>
                          {newerror && data.project_cost == null && (
                            <p style={{ color: "red" }}>
                              project cost Required
                            </p>
                          )}
                        </div>
                        <div className="col-md-3">
                          <label class="form-label">GDV</label>
                          <span
                            className="pound"
                            style={{ marginLeft: "-33px" }}
                          >
                            £
                          </span>
                          <input
                            type="text"
                            name="gdv"
                            placeholder="GDV"
                            class="form-control"
                            value={data.gdv}
                            onChange={completedvalidation}
                            style={{ paddingLeft: "45px" }}
                          ></input>
                          {newerror && data.gdv == null && (
                            <p style={{ color: "red" }}>GDV Required</p>
                          )}
                        </div>
                        <div className="col-md-3">
                          <label class="form-label">Profit</label>
                          <span
                            className="pound"
                            style={{ marginLeft: "-41px" }}
                          >
                            £
                          </span>
                          <input
                            type="text"
                            name="gain_profit"
                            placeholder="Profit"
                            class="form-control"
                            value={data.gain_profit}
                            style={{ paddingLeft: "45px" }}
                            onChange={completedvalidation}
                          ></input>
                          {newerror && data.gain_profit == null && (
                            <p style={{ color: "red" }}>Profit Required</p>
                          )}
                        </div>

                        <div class="col-md-6 mt-4">
                          <div className="form-group preview">
                            <ul
                              className="imgouters"
                              id="sortable3"
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                // display:"inline",
                                width: "500px",
                                // height:"50/0px",
                                gap: "10px",
                                listStyleType: "none",
                                textDecoration: "none",
                              }}
                            >
                              {beforeimg &&
                                beforeimg.length > 0 &&
                                beforeimg.map((item, index) => {
                                  return (
                                    <li
                                      id={index}
                                      className="ui-state-default3"
                                      style={{
                                        margin: "10px 10px 10px 10px",
                                        //border: "1px solid #ddd",
                                        display: "flex",
                                      }}
                                    >
                                      <img
                                        style={{
                                          height: "3.5rem",
                                          width: "3.5rem",
                                          borderRadius: "50%",
                                          // margin: "10px 10px 10px 20px",
                                        }}
                                        src={item}
                                        alt=""
                                      />
                                      <button
                                        type="button"
                                        style={{
                                          position: "relative",
                                          height: "20px",
                                          width: "20px",
                                          margin: "-10px",
                                          borderRadius: "50%",
                                          backgroundColor: "#00D0F1",
                                        }}
                                        onClick={() => deleteFilebefore(index)}
                                      >
                                        <i
                                          class="bi bi-x"
                                          style={{
                                            margin: "inherit",
                                            color: "white",
                                            position: "relative",
                                            bottom: "2px",
                                          }}
                                        ></i>
                                      </button>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>

                          <div className="form-group"></div>
                          <Button
                            className="pharmacyupload"
                            variant="contained"
                            component="label"
                          >
                            {" "}
                            <input
                              type="file"
                              hidden
                              className="form-control"
                              accept="image/png, image/jpeg"
                              onChange={uploadSingleFilebefore}
                              onClick={(event) => {
                                event.target.value = null;
                              }}
                              multiple
                            />
                            Before Image
                          </Button>
                          {alert2 && data.before.length == 0 && (
                            <p style={{ color: "red" }}>Please Upload Image</p>
                          )}
                        </div>
                        <div class="col-md-6 mt-4">
                          <div className="form-group preview">
                            <ul
                              className="imgouters"
                              id="sortable4"
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                // display:"inline",
                                width: "500px",
                                // height:"50/0px",
                                gap: "10px",
                                listStyleType: "none",
                                textDecoration: "none",
                              }}
                            >
                              {afterimg &&
                                afterimg.length > 0 &&
                                afterimg.map((item, index) => {
                                  return (
                                    <li
                                      id={index}
                                      className="ui-state-default4"
                                      style={{
                                        margin: "10px 10px 10px 10px",
                                        //border: "1px solid #ddd",
                                        display: "flex",
                                      }}
                                    >
                                      <img
                                        style={{
                                          height: "3.5rem",
                                          width: "3.5rem",
                                          borderRadius: "50%",
                                          // margin: "10px 10px 10px 20px",
                                        }}
                                        src={item}
                                        alt=""
                                      />
                                      <button
                                        type="button"
                                        style={{
                                          position: "relative",
                                          height: "20px",
                                          width: "20px",
                                          margin: "-10px",
                                          borderRadius: "50%",
                                          backgroundColor: "#00D0F1",
                                        }}
                                        onClick={() => deleteFileafter(index)}
                                      >
                                        <i
                                          class="bi bi-x"
                                          style={{
                                            margin: "inherit",
                                            color: "white",
                                            position: "relative",
                                            bottom: "2px",
                                          }}
                                        ></i>
                                      </button>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>

                          <div className="form-group"></div>
                          <Button
                            className="pharmacyupload"
                            variant="contained"
                            component="label"
                          >
                            {" "}
                            <input
                              type="file"
                              hidden
                              className="form-control"
                              accept="image/png, image/jpeg"
                              onChange={uploadSingleFileafter}
                              onClick={(event) => {
                                event.target.value = null;
                              }}
                              multiple
                            />
                            After Image
                          </Button>
                          {alert2 && data.after.length == 0 && (
                            <p style={{ color: "red" }}>Please Upload Image</p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div class="col-12">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={checked}
                        onChange={(e) => checkbox(e)}
                        id="invalidCheck"
                        required
                      ></input>
                      <label class="form-check-label" for="invalidCheck">
                        Is Active
                      </label>
                    </div>
                  </div>

                  <div class="col-6">
                    <button
                      class="btn btn-primary"
                      type="submit"
                      onClick={(e) => newusercreat(e)}
                    >
                      Submit form
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      class="btn btn-danger"
                      style={{ float: "right" }}
                      onClick={(e) => cancelnewuser(e)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyEdit;

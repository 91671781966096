// export const baseurl = "http://122.186.126.218:8012";
// const baseurl = "http://192.168.5.71:8001";
// export const baseurl = "http://3.6.77.151:8003";
export const baseurl = "https://api.deaneinvestment.com";

export const loginapi = baseurl + "/adminlogin/";

// dean investment admin

// get
export const propertyget = baseurl + "/adminpropertyview/";

// create
export const propertycreate = baseurl + "/property/v1/";

// create presigned
export const presignedurl2 = baseurl + "/presignedurl/";

// edit
export const propertyedit = baseurl + "/property/v1/";

export const deleteapi = baseurl + "/property/v1/";

import React, { useEffect, useState, useMemo } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
} from "react-table";
import "./tablestyle.scss";
var pageindexloc;

function BasicTable(props) {
  const columns = useMemo(() => props.columns);
  const data = props.data;
  // //debugger;
  ////console.log(data.length,"tabledata87868768768")

  // const tableInsatnce = useTable({
  //   columns,
  //   data,
  //   initialState: { pageIndex: 0, pageSize: `${data[0] ? data.length : 10}` }
  // }, usePagination)

  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   rows,
  //   prepareRow,
  //   rowsPerPage,
  // } = useTable({
  //   columns,
  //   data,
  // });

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: props.dtpageindex,
        pageSize: props.dtpagesize,
      },
      manualPagination: true,
      pageCount:
        props.datacount < 10 || props.datacount == null
          ? 1
          : Math.ceil(props.datacount / props.dtpagesize + 1),
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );
  // const {
  //   page,
  //   canPreviousPage,
  //   canNextPage,
  //   pageOptions,
  //   pageCount,
  //   gotoPage,
  //   nextPage,
  //   state,
  //   visibleColumns,
  //   setGlobalFilter,
  //   preGlobalFilteredRows,
  //   previousPage,
  //   setPageSize,

  //   state: { pageIndex, pageSize },
  // } = tableInstance;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  // //console.log(props.pageindex,props.pagesize,props.datacount,pageIndex,pageSize)

  const fetchData = ({ pageSize, pageIndex }) => {
    // //console.log("fetchData is being called", pageSize, pageIndex);
    pageindexloc = pageIndex;
    if (pageindexloc == 0) {
      pageindexloc = 1;
    }
    console.log(pageindexloc,pageIndex,"kkkk")

    props.pagesize(pageSize);
    props.pageindex(pageindexloc);
  };

  React.useEffect(() => {
    fetchData && fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  useEffect(()=>{
    gotoPage(1);

  },[props.value])
  const dropdowntable = () => {
    gotoPage(1);
  };
  return (
    <>
      <div
        className="showrow"
        style={{ padding: "10px", fontSize: "8px", display: "flex" }}
      >
        <p
          style={{
            padding: "5px",
            marginRight: "4px",
            marginBottom: "0",
            fontSize: "15px",
          }}
        >
          Show
        </p>
        <FormControl variant="standard" sx={{ width: 60 }}>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            disableUnderline
            // value={age}
            value={pageSize}
            // onChange={(e) => dropdownhandlechange(row.row.original.id)}
            onChange={(e) => {
              // //console.log(e)
              setPageSize(Number(e.target.value));
              dropdowntable(e);
            }}
            inputProps={{
              padding: "0",
              IconComponent: () => null,
              MuiSelect: {
                outlined: {
                  "&:focus": {
                    backgroundColor: "#FFF",
                  },
                },
              },
            }}
            label="Age"
            defaultValue="none"
            style={{
              fontSize: "15px",
              border: "1px solid #dddddd",
              borderRadius: "5px",
              boxShadow: "none",
              width: "49px",
              height: "calc(1.5em + 0.5rem + -1px)",
              paddingLeft: "6px",
            }}
          >
            <MenuItem value="none" disabled style={{ display: "none" }}>
              Select
            </MenuItem>
            {/* {[10,20,50].map((pageSize) => (
                  <MenuItem value={pageSize} style={{alignItems:"center"}}>{pageSize}</MenuItem> */}
            <MenuItem
              value={10}
              style={{ alignItems: "center" }}
              disabled={props.datacount < 10}
            >
              {10}
            </MenuItem>
            <MenuItem
              value={20}
              style={{ alignItems: "center" }}
              disabled={props.datacount < 10}
            >
              {20}
            </MenuItem>
            <MenuItem
              value={50}
              style={{ alignItems: "center" }}
              disabled={props.datacount < 10}
            >
              {50}
            </MenuItem>

            {/* ))} */}
          </Select>
        </FormControl>
        <p
          style={{
            padding: "5px",
            marginRight: "4px",
            marginBottom: "0",
            fontSize: "15px",
          }}
        >
          Entries
        </p>
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        {page.length > 0 ? (
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          // <div style={{ position: "relative", left: "10%" }}>No Data....</div>
          <tr>
            <td colSpan="8" style={{ textAlign: "center" }}>
              No Data....
            </td>
          </tr>
        )}
      </table>
      <div
        className="row"
        style={{ width: "100%", backgroundColor: "white", margin: "0" }}
      >
        <div className=" pagination" style={{ justifyContent: "end" }}>
          <div className="showrow1" style={{ padding: "4px" }}>
            <button
              className="btn btn prim"
              style={{ border: "none" }}
              onClick={() => {
                previousPage();
              }}
              // onClick={() => {handleIncrement()}}

              disabled={pageIndex == 1}
            >
              {"<"}
            </button>
            <span style={{ padding: "10px" }}>
              <strong style={{ fontSize: "12px" }}>
                {pageIndex}-
                {Math.ceil(
                  props.datacount == null
                    ? 1
                    : props.datacount / props.dtpagesize
                )}{" "}
                of{" "}
                {Math.ceil(
                  props.datacount == null
                    ? 1
                    : props.datacount / props.dtpagesize
                )}
              </strong>
            </span>
            <button
              style={{ border: "none" }}
              className="btn btn prim"
              onClick={() => {
                nextPage();
              }}
              // onClick={() => {handleDecrement()}}

              disabled={
                pageIndex == Math.ceil(props.datacount / props.dtpagesize)
              }
            >
              {">"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicTable;
